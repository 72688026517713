
import { reactive } from 'vue';

const state = reactive({
    info: JSON.parse(localStorage.getItem('user') || '{"contact":""}')
});

const methods = {
    get() {
        return state.info;
    },
    update(item){
        localStorage.setItem("user", JSON.stringify(item));
    },
    clear(){
        localStorage.setItem("user", JSON.stringify([]));
    },
    // Другие методы управления корзиной
};

// Экспортируем состояние и методы
export default {
    state: state, // предотвращаем внешние изменения напрямую
    ...methods
};