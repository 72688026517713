<template>
  <div class="container">

    <div class="d-fl fl-clm m-c back">
      <div class="txt-32 t-bold">О НАШЕЙ КОМПАНИИ</div>
      <slider />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import Slider from "@/components/slider.vue";
const expandedItemId = ref(null);

const toggleItem = (id) => {
  if (expandedItemId.value === id) {
    expandedItemId.value = null;
  } else {
    expandedItemId.value = id;
  }
};

</script>
<script>

export default{
  components: {

  },
  data() {
    return {
      slides: 7,
      maxlines: 18,
      lines:[],
    }
  },
  created() {
    this.makelines();
  },
  methods:{
    makelines(){
      for(let i=0;i<this.maxlines;i++){
        this.lines.push(i%2);
      }
      console.log(this.lines)
    }
  }
}
</script>

<style scoped>
.container {
  /* Стили контейнера */
  width: 72%;
  margin: auto;
  display: block;
  padding-top: 50px;
}

.back{
  background: none;
  background-position-y: -4px;
  width: 100vw;
  height: 512px;
  max-height: 512px;
  position: absolute;
  left: 0;
  background-size: 27%;
}
.colw{
  color: #eeeeee;
}
/*
.line{
  width: 112px;
  height: 378px;
  transform: rotate(11deg);
  margin-top: -48px;
  clip-path: polygon(118px 338px, -80px 375px, 0px 58px, 118px 34px)
}

.lined{
  width: 112px;
  height: 353px;
  transform: rotate(11deg);
  margin-top: -86px;
  -webkit-clip-path: polygon(118px 322px, -80px 375px, 0px 65px, 118px 34px);
  clip-path: polygon(114px 298px, -280px 375px, 0px 56px, 117px 34px);
}

.s_l{
  background: var(--col-lightblue);
}

.s_b{
  background: var(--col-midblue);
}
*/
</style>