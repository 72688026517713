import axios from 'axios';
import storage from "@/storage";

const APIupload = axios.create({
    baseURL: 'https://riche.bot-cloud.ru/public/',//'http://riche.my/public/',
});

const token = '8485d705bdbc5ac3a48a1c8a43430038e3584e284815e71a28cf1a6031c6293d72fcdade20b8416f3322e6571ab5b98915779b030327dde37e88b465cc08bca2';//storage.token_get();

APIupload.defaults.headers.common['Authorization'] = `Bearer ${token}`;

export default APIupload;
