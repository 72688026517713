<template>
  <div class="d-fl fl-clm">
    <input class="inputs" type="text" :placeholder="params.placeholder" v-model="params.name">
    <input class="btn_class" type="button" value="Создать" @click="params.click(params.name)">
  </div>
</template>

<script>
export default {
  name: "frmGroup",
  props:{
    params:{
      type: Object,
      required: true,
      default:{
        name: 'unnamed',
        click: ()=>{ alert('Set please action in param!') }
      }
    }
  }
}
</script>

<style scoped>

.inputs{
  height: 32px;
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 2px;
  outline: none;
  color: #5648ed;
  position: relative;
  width:90%;
  text-align: center;
}
</style>
