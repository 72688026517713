<template>
  <div>
    <orders-item v-for="order in orders" :params="order" :key="order.id"/>
  </div>
</template>

<script>
import OrdersItem from "@/components/Orders/OrdersItem.vue";

export default {
  name: "Orders",
  components: {OrdersItem},
  mounted() {
  }
}
</script>

<style scoped>

</style>