<template>
  <div class="animated-div" id="movingDiv"><img :src="require('@/assets/belaz.png')" class="wdtimg"></div>
</template>

<script>
export default {
  name: "AnimateBelaz",
  data(){
    return{
      div:{}
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.div = document.querySelector('.animated-div');
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      //const div = document.querySelector('.animated-div');//this.$refs.movingDiv;
      if(this.div != null){
        const divPosition = this.div.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (divPosition <= windowHeight) {
          this.div.classList.add('move-left');
        } else {
          this.div.classList.remove('move-left');
        }
      }
    }
  }
};
</script>

<style>
.animated-div {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 0;
  margin-top: -24px;
  transition: transform 1.5s;
}

.move-left {
  transform: translateX( calc(100vw - 532px) ); /* Двигаем влево на 100% ширины элемента */
}

.wdtimg{
  width: 512px;
}
@media screen and (max-width: 900px) {
  .wdtimg {
    width: 320px;
  }
}
@media screen and (max-width: 500px) {
  .animated-div {
    left: -320px;
  }
  .move-left {
    transform: translateX( calc(100vw - 0px) );
  }
}

</style>