// preloader.js
export const preloader = {
    isLoading: false,
    pre_id: 0,
    prel: {},
    show() {
        this.make();
    },
    hide() {
        let pr = document.querySelector('#'+this.prel.id);
        if(pr != null) pr.parentNode.removeChild(pr);
    },
    make(){
        this.prel = document.createElement('div')
        this.prel.id = 'prell_' + Date.now();
        this.pre_id = this.prel.id;
        this.prel.style.position = 'fixed';
        this.prel.style.width = '100vw';
        this.prel.style.height = '100vh';
        this.prel.style.background = 'rgba(10,10,10, 0.5)';
        this.prel.style.top = '0px';
        this.prel.style.left = '0px'

        let inbox = document.createElement('div');
        inbox.style.position = 'absolute';
        inbox.style.width = '128px'
        inbox.style.height = '128px'
        inbox.style.left = '50%'
        inbox.style.top = '50%'

        let box = document.createElement('div');
        box.style.position = 'relative';
        box.style.width = '128px'
        box.style.height = '128px'
        box.style.left = '-50%'
        box.style.top = '-50%'
        box.style.borderRadius = '8px';
        box.style.background = 'url('+require('@/assets/1494.gif')+') center center no-repeat #fff';
        box.style.color = '#36f'
        box.style.fontSize = '16px';

        inbox.append(box)
        this.prel.append(inbox)
        document.body.append(this.prel)
    },
};
