<template>
  <div v-if="params.show" class="screen" @click="closed">
    <div class="inscreen" :style="'width:'+params.width+'px; height:'+params.height+'px;'">
      <div class="window" :style="'width:'+params.width+'px; height:'+params.height+'px;'">
        <component :is="params.content" :params="params.frmparam"></component>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "Window",
  props:{
    params:{
      type: Object,
      required: true,
      default:{
        width:320,
        height:240,
        show: true
      }
    }
  },
  watch:{
    params:{
      handler(val){
        setTimeout(()=> {
          let win = document.querySelector('.window');
          if (win != undefined) {
            win.style.transform = 'scale(1)';
          }
        },10);
      }
    }
  },
  created() {

  },
  mounted() {
    console.log('mount!')
  },
  methods:{
    closed(item){
      let obj = item.target.className;
      if(obj == 'screen' || obj == 'inscreen'){
        this.params.show = false;
      }
    },
    animate(){
      let win = document.querySelector('.window');
      if (win != undefined) {
        win.style.transform = 'scale(1)';
      }
    }
  }
}
</script>

<style scoped>

.screen{
  width:100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  background: rgba(10,10,10,0.5);
  z-index: 9999;
}

.inscreen{
  position: absolute;
  top: 50%;
  left: 50%;
}

.window{
  position: relative;
  top: -50%;
  left: -50%;
  background: #ccc;
  border-radius: 8px;
  border:1px solid #bbb;
  box-shadow: 0px 0px 15px rgba(10,10,10,0.125);
  transform: scale(0.5);
  transition-property: background-color, box-shadow, transform;
  transition:  0.3s ease;
}

@media screen and (max-width: 1050px) {
  .inscreen{
    position: absolute;
    top: 50%;
    left: 50%;

    max-width: 98vw;
  }
  .window{
    max-width: 47.5vh;
    top: -75%;
    height: initial !important;
  }

}

.active{
  transform: scale(1);
}

</style>