import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from './axios'
//import { VueYandexMaps } from 'vue-yandex-maps'

//createApp(App).use(router).mount('#app')
const app = createApp(App)
app.config.globalProperties.$axios = axios
app.use(router).mount('#app')

const settings = {
    apiKey: 'ecb116f9-4a07-4dd6-a46a-9620f91070bc', // замените на ваш ключ API
    lang: 'ru_RU',
    version: '2.1'
};

//app.use(VueYandexMaps, settings);