<template>
  <div class="slider-container">
    <div class="slider-window">
      <div
          class="slider-content"
          :style="{ transform: `translateX(-${currentSlide * 100}%)`}"
      >
        <div class="slide" v-for="(slide, index) in slides" :key="index" :class="{ transl : (index === currentSlide)}" @mouseenter="mousein=true;" @mouseleave="mousein=false;">
          <div class="inslide txt-18">{{ slide.info }}</div>
        </div>
      </div>
    </div>
    <div class="d-fl w-100">
      <button @click="prevSlide" style="margin-right: auto"> <img :src="require('@/assets/up.svg')" class="m-c imgsi" style="margin:-36px;rotate: -90deg; "> </button>
      <button @click="nextSlide" style="margin-left: auto"> <img :src="require('@/assets/up.svg')" class="m-c imgsi" style="margin:-36px;rotate: 90deg;"> </button>
    </div>
  </div>
</template>

<script setup>
</script>

<script>
import {ref} from "vue";

export default {
  mounted() {
    this.timer();
  },
  data(){
    return{
      currentSlide : 0,
      second: 10,
      mousein: false,
      slides: [
        // Замените строки URL-адресами ваших изображений
        {id: 0, info: 'Общество с ограниченной ответственностью «Торгово-Промышленная Группа «РИШ ДЕВЕЛОПМЕНТ» — одна из лидирующих компаний в России по поставкам электротехнического оборудования. Компания была основана в 2007 году и специализируется на производстве и поставках широкого спектра комплектующих и запасных частей для электрических машин применяющихся в металлургической, горнодобывающей, энергетической промышленностях и электротранспорте.\n'},
        {id: 1, info: 'Одним из ключевых преимуществ ООО «ТПГ «РД» является ее клиентоориентированность. Мы предоставляем нашим клиентам индивидуальный подход, учитывая его конкретные потребности и требования.'},
        {id: 2, info: 'Основные производственные мощности размещены в городе Миасс, Челябинской области. Производство оснащено современным оборудованием и имеет высококвалифицированных специалистов, что гарантирует высокое качество продукции, отвечающее всем стандартам качества. Компания стремимся к постоянному развитию. Работаем над улучшением производственных процессов и расширением ассортимента продукции, чтобы удовлетворить все потребности наших клиентов и обеспечить им наилучший опыт работы с нашей компанией.'},
        {id: 3, info: 'ООО «ТПГ «РД» активно участвует в развитии своих сотрудников и предоставляет им возможности для профессионального роста и развития.'},
        {id: 4, info: 'Основные партнеры — это крупные промышленные предприятия России, Белоруссии, Казахстана и ближнего зарубежья, а также широкий круг малых предприятий. В целом, наша компания является надежным партнером для клиентов, которые ищут высококачественную продукцию и профессиональную поддержку в области электротехнического оборудования.'},
      ]
    }
  },
  methods:{
    timer()
    {
      if(this.second > 1) {
        if(!this.mousein) {
          this.second--;
        }
      }else{
        this.nextSlide();
      }
      setTimeout(()=>{this.timer()}, 1000)
    },
    nex_t()
    {
      this.nextSlide()
      setTimeout(() => {
        this.nex_t();
      }, 10000);
    },
    nextSlide () {
      this.currentSlide = (this.currentSlide + 1) % this.slides.length;
      this.second = 10;
    },
    prevSlide(){
      this.currentSlide = (this.currentSlide - 1 + this.slides.length) % this.slides.length;
      this.second = 10;
    },
  }
}
</script>

<style scoped>
.slider-container {
  position: relative;
  overflow: hidden;
  width: 50%;
  margin: auto;
}

.slider-window {
  width: 100%;
}

.slider-content {
  display: flex;
  transition: transform 0.8s ease-in-out;
}

.slide {
  color: #111;
  flex: 0 0 100%;
  width: 100%;
  transform: scale3d(0.1, 0.1, 0.1);
  transition: transform 0.8s ease-in-out;
  /*transition-property: transform;
  transition-duration: .8s;*/
}

.transl
{
  transform: scale3d(1, 1, 1);
  transition: transform 0.8s ease-in-out;
  /*transition-property: transform;
  transition-duration: .8s;*/
}

button {
  /*position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;*/
  width: 64px;
  height: 64px;
  border: none;
  outline: none;
  background: none;
  border-radius: 50%;
  text-align: center;
  line-height: 64px;
  font-size: 32px;
}

button:first-child {
  left: 10px;
}

button:last-child {
  right: 10px;
}
.inslide
{
  width: 512px;
  height: 256px;
  margin: auto;
  /*border: 1px solid #eee;
  border-radius: 8px;*/
  padding: 16px;
}
@media screen and (max-width: 1200px) {
  .slider-container {
    width: 95%;
  }
  button {
    height: 93px;
  }
}
@media screen and (max-width: 600px) {
  .slider-container {
    width: 100%;
  }
  .inslide {
    font-size: 14px !important;
    height: 162px;
    width: 308px;
  }
}
</style>
