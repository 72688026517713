import { createRouter, createWebHistory } from 'vue-router'
import main from "@/pages/Main.vue";
import admin from "@/pages/Admin.vue";
import par from "@/pages/Par.vue";
import salt from "@/pages/Salt.vue";
import catalogs from "@/pages/Catalogs.vue";

const routes = [
    // { path: '/about', component: About }
    { path: '/', component: salt },
    { path: '/odmin', component: admin },
    { path: '/par', component: par },
    { path: '/catalogs', component: catalogs },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

export default router