<template>
  <div>
    <!--<div class="content">Прокрутите вниз</div>-->
    <section id="target-start"></section>

    <div class="setk">
      <div class="title_page">
        Производим и поставляем детали <br>для горнодобывающей спецтехники на выгодных условиях
      </div>
    </div>

    <div class="video-background">
      <video autoplay muted loop>
        <source :src="require('@/assets/blz.mp4')" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>

    <div class="header">
      <table class="header_table">
        <tr>
          <td class="header_td"></td>
          <td class="header_td">
            <img src="@/assets/logo.png" class="header_img" alt="" @click="mainpage()">
          </td>
          <td class="header_td mytitle">

          </td>
          <td class="header_td">
            <stickybar/>
          </td>
        </tr>
      </table>
    </div>

    <div id="target-info"></div>

    <section id="">
      <spec-info/>
    </section>

    <div id="target-catalog"></div>
    <section id="" class="section-electro">
      <div class="block" @click="catalogpage()">
          <div class="inblck">
                  ПЕРЕЙТИ В КАТАЛОГ
          </div>
      </div>
      <animate-electro />
        <cataloglite-r-n-d />
      <animate-belaz />
   </section>

    <div id="target-about"></div>
    <section id="">
      <about-info/>
    </section>

    <div id="target-contact"></div>
    <section id="">
      <contacts/>
    </section>

    <div class="content back"></div>

    <div class="footer">
      Авторские права Рише Девелопмент 2024 Ⓒ
      <div class="social">
        <a href="https://tenchat.ru/2830611?utm_source=5d172309-293b-42d7-bf72-45c834d64471">
          <img :src="require('@/assets/tenchat.png')" alt="TenChat" width="48">
        </a>
      </div>
    </div>

    <div class="upper" :class="{uppershow: offset <= -95}" @click="scrollTo('target-start')">
      <img src="@/assets/up.svg" class="m-c imgsi" style="margin: -40px;">
    </div>

    <!--<div class="debugger">
      {{ offset }}
    </div>-->

  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Stickybar from "@/components/salt/stickybar.vue";
import SpecInfo from "@/components/SpecInfo.vue";
import Contacts from "@/components/Contacts.vue";
import AboutInfo from "@/components/AboutInfo.vue";
import AnimateBelaz from "@/components/tests/AnimateBelaz.vue";
import AnimateElectro from "@/components/tests/AnimateElectro.vue";
import CatalogliteRND from "@/components/catalogs/catalogliteRND.vue";

const offset = ref(0);

const handleScroll = () => {
  offset.value = - window.scrollY / 6; // Подстроить коэффициент для изменения скорости параллакса
  const mmenu = document.querySelector('.header');
  if(window.scrollY > 0){ mmenu.classList.add('header_shadow') ;}else{ mmenu.classList.remove('header_shadow') ;}
  let sections = document.querySelectorAll('section');
  sections.forEach(sec=>{
    const secPosition = sec.getBoundingClientRect().top + 32;
    const windowHeight = window.innerHeight;

    if (secPosition <= windowHeight) {
      sec.classList.add('section_animate');
    } else {
      sec.classList.remove('section_animate');
    }
  })

};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

</script>

<script>

export default{
  name: "Salt",

    methods:{
      mainpage(){
        window.location.href = './';
      },
      catalogpage(){
        window.location.href = './catalogs'
      }
    }
}

</script>

<style scoped>

.inblck{
    width: 312px;
    height: 60px;
    line-height: 60px;
    background: #eeeeee;
    color: #222222;
    font-size: 26px;
    padding: 32px;
    margin: auto;
    text-align: center;
    border-radius: 16px;
}

.social{
  width: 50%;
  margin-left: auto;
  display: flex;
  margin-top: -32px;
}

.social a{
  margin-left: auto;
  margin-right: 16px;
}

.parallax {
  /* Путь к вашему фоновому изображению bel32.jpg*/
  background-image: url('@/assets/bela2.jpg');

  /* Высота секции с параллакс-эффектом */
  height: 100vh;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Добавим немного отступа для визуального эффекта*/
  padding: 114px 0 0 0;
  color: white;
  text-align: center;
  font-size: 36px;
  font-family: Arial, sans-serif;
  margin-top: -174px;
}
.parallax_footer {
  background-image: url('@/assets/kowshi.jpg');

  /* Высота секции с параллакс-эффектом */
  height: 370px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Добавим немного отступа для визуального эффекта
  padding: 100px 0;*/
  color: white;
  text-align: center;
  font-size: 36px;
  font-family: Arial, sans-serif;
  margin-top: -174px;
}

.block{
  width: 100%;
  height: calc(100% - 224px);
    display: flex;
  position: absolute;
  background: rgba(10,10,10,0.25);
  z-index: 3;
  opacity: 0.5;
  transition-property: opacity;
  transition-duration: .5s;
  cursor: pointer;
}

.block:hover{
    opacity: 1;
}

.content {
  height: 128px;
  background: rgba(200,200,200,0.25);
  text-align: center;
  padding-top: 50px;
  font-family: Arial, sans-serif;
}

.header{
  height: 128px;
  background: none;
  position: fixed;
  z-index: 99;
  transition-property: box-shadow, background-color;
  transition-duration: .3s;
  top: 0;
  /*filter: blur(8px);*/
}
.header_table {
  min-width:1024px;
  width:100%;
  height:100%;
  margin: auto;
}
.header_td:nth-child(2) {
  width: 30%;
}
.header_img {
  width: 224px;
  margin-top: 8px;
}
.header_shadow{
  box-shadow: 0 0 15px rgba(0,0,0,.25);
  background-color: #fff;
}

table {
  font-size: 14px;
  color: #111111;
}
.mytitle {
  text-align: left;
  padding-left: 24px;
  line-height: 20px;
  width:20%;
}
a{
  font-size: 14px;
  font-weight: bold;
  color: #111;
  text-decoration: unset;
  cursor: pointer;
}
a:hover{
  color: #555;
}
input {
  height: 24px;
  background: rgba(30,30,30,.75);
  border: 1px solid #ccc;
  border-radius: 14px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  outline: none;
  color: #eee;/*#5648ed;*/
  position: relative;
  width: 90%;
  text-align: center;
}
.setk{
  background: rgba(255,255,255,0.5);
  width: 100vw;
  height: 100vh;
  position: absolute;
}
.black{
  background-color: rgba(5,5,5,0.75);
}
.title_page {
  width: 100%;
  height: 74px;
  /* background: rgba(10,10,10,0.75); */
  text-align: center;
  line-height: 74px;
  color: #111;
  position: absolute;
  bottom: 50vh;
  font-size: 32px;
  font-weight: bold;
}
section{
  min-height: 320px;
  padding-top: 256px;
  padding-bottom: 64px;
  /*border-top:1px solid #bbb;*/
  margin-top: 256px;
  opacity: 0;
  transition-property: margin-top, opacity;
  transition-duration: 1.5s;
}
.section-electro {
  margin-top: 128px;
}
.section_animate{
  margin-top: 0;
  opacity: 1;
}

#target-start{
  padding: 0px;
  position: absolute;
  top: 0px;
}
.upper{
  position: fixed;
  z-index: 999;
  /*background: #3366ff;*/
  color: #eee;
  line-height: 48px;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  width: 48px;
  height: 48px;
  right: 22px;
  top: 38px;
  border-radius: 50%;
  cursor: pointer;
  /*box-shadow: 0 0px 7px #3366ff;*/
  opacity: 0;
  transition-property: opacity;
  transition-duration: .5s;
}
.uppershow {
  opacity: 1;
}
.footer {
  margin-top: -24px;
  color: #eee;
  text-align:left;
  padding-left: 16px;
  display: flex;
}

.back{
  background: url('../assets/back_catalog.jpg');
  background-position-y: -80px;
  background-size: 9%;
}

.video-background {
  position: relative;
  width: 100%;
  height: 100vh; /* или любая другая высота */
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100v;
  z-index: -1; /* Поместите видео под текстовое содержимое */
}
@media screen and (max-width: 1300px) {
  .header {
    width: 100%;
  }
  .mytitle {
    width: 0;
  }
}
@media screen and (max-width: 1024px) {
  .header_table {
    min-width: initial;
  }
  .section_animate {
    padding-top: 130px;
  }
  .section-electro {
    margin-top: 0;
  }
  .video-background video {
    width: initial;
    height: 100vh;
  }
  .upper {
    top: 19px;
  }
}
@media screen and (max-width: 780px) {
  .title_page {
    font-size: 24px;
  }
  .header_td:first-child {
    width: 15px;
  }
  .section_animate {
    padding-top: 30px;
  }
  .section_animate .container {
    width: 92%;
  }
  .sticky-element {
    display: none;
  }
  .upper {
    right: 77px;
  }
}
@media screen and (max-width: 500px) {
  .header {
    height: 75px;
  }
  .title_page {
    line-height: 40px;
    font-size: 16px;
  }
  .header_img {
    width: 160px;
  }
  .header_table {
    height: 66%;
  }
  .upper {
    top: -5px;
  }
}
</style>
