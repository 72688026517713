<template>
  <div class="d-fl fl-clm">
    <input class="inputs" type="text" placeholder="Название" v-model="params.name"/>
    <input class="inputs" type="text" placeholder="Область применения" v-model="params.area"/>
    <input class="inputs" type="text" placeholder="Ширина" v-model="params.size_w"/>
    <input class="inputs" type="text" placeholder="Высота" v-model="params.size_h"/>
    <input class="inputs" type="text" placeholder="Глубина" v-model="params.size_d"/>
    <input class="inputs" type="text" placeholder="Маркировка" v-model="params.mark"/>
    <input class="inputs" type="text" placeholder="Чертеж" v-model="params.drawing"/>
    <input class="inputs" type="text" placeholder="ФОТО" v-model="params.photo_path" @click="showgallery=true"/>
    <input class="btn_class" type="button" value="Сохранить" @click="params.click(params)"/>

    <div v-if="showgallery" class="gallery">
      <div class="incenter">
        <div class="center">
          <gallery :params="gallery_param" />
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Gallery from "@/components/catalogs/gallery.vue";
import cart from "@/cart";

export default {
  name: "frmGroup",
  components: {Gallery},
  props:{
    params:{
      type: Object,
      required: true,
      default:{
        name: 'unnamed',
        click: ()=>{ alert('Set please action in param!') }
      }
    }
  },
  data(){
    return{
      showgallery: false,
      gallery_param: {
        dnd: false,
        returning: true,
        name: this.params.photo_path,
        action: this.hideGallery,
      }
    }
  },
  methods:{
    hideGallery(item){
      //console.log(item)
      this.params.photo_path = item.name;
      this.showgallery = false;
    },
    add(){
      let item = JSON.parse(JSON.stringify(this.params));
      item.count = 1;
      cart.plus(item);
      this.showaddbtn = true;
    },
  }
}
</script>

<style scoped>

.inputs{
  height: 32px;
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 2px;
  outline: none;
  color: #5648ed;
  position: relative;
  width:90%;
  text-align: center;
}

.incenter{
  width: 810px;
  height: 470px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.center{
  width: 810px;
  height: 470px;
  position: relative;
  top: -50%;
  left: -50%;
  background: #eeeeee;
  border-radius: 8px;
}
</style>
