import axios, {create} from 'axios';
import storage from "@/storage";
import { preloader } from './preloader';
import {createElementBlock} from "vue";

const API = axios.create({
    baseURL: 'https://riche.bot-cloud.ru/public',//http://riche.my/public/',
    headers: {
        'Content-Type': 'application/json',
    },
});

const token = '8485d705bdbc5ac3a48a1c8a43430038e3584e284815e71a28cf1a6031c6293d72fcdade20b8416f3322e6571ab5b98915779b030327dde37e88b465cc08bca2';//storage.token_get();
API.defaults.headers.common['Authorization'] = `Bearer ${token}`;

//---PRELOADER--//
API.interceptors.request.use(config => {
    preloader.show();
    return config;
}, error => {
    preloader.hide();
    return Promise.reject(error);
});

API.interceptors.response.use(response => {
    preloader.hide();
    return response;
}, error => {
    preloader.hide();
    return Promise.reject(error);
});

export default API;
