<template>
  <div>
    <div v-if="!authen">
      <div class="winauth">
        <div class="inwinauth d-fl fl-clm">
          <div class="authtitle"> Введите логин и пароль <br> для входа </div>
          <input type="text" placeholder="Логин" id="login"/>
          <input type="password" placeholder="Пароль" id="password"/>
          <div class="btn" :class="{anidiv:loading}">
            <input type="button" style="width:99%;margin-top: 1px;margin-bottom: 1px;" value="Вход" @click="auth()"/>
          </div>
        </div>
        <div class=r_status v-if="response_status!=''">{{response_status}}</div>
      </div>
    </div>
    <div v-if="authen">

      <div class="d-fl">
        <div class="tabs ml-it" @click="setActivity(1)" :class="{active:activity==1}">Каталоги</div>
        <div class="tabs" @click="setActivity(2)" :class="{active:activity==2}">Заявки</div>
        <div class="tabs" @click="setActivity(3)" :class="{active:activity==3}">Клиентты</div>
        <div class="d-fl fl-clm user_info">
          <span> Пользователь </span>
          <span> Иванов Иван </span>
        </div>
      </div>

      <div v-if="activity == 1" class="intabs">
        <table class="CatalogTab">
          <tr>
            <td style="width: 128px">
              <div class="d-fl fl-clm w-10">
                <div class="cattabs ml-it" @click="setCatalog(1)" :class="{catactive:catalog==1}">Простой</div>
                <div class="cattabs ml-it" @click="setCatalog(2)" :class="{catactive:catalog==2}">Конфигуратор</div>
                <div class="cattabs ml-it" @click="setCatalog(3)" :class="{catactive:catalog==3}">Сложный</div>
              </div>
            </td>
            <td>
              <div v-if="catalog == 1" class="incattabs">
                <cataloglite />
              </div>
              <div v-if="catalog == 2" class="incattabs">

              </div>
              <div v-if="catalog == 3" class="incattabs">

              </div>
            </td>
          </tr>
        </table>
      </div>


      <div v-if="activity == 2" class="intabs">
        <orders />
      </div>
      <div v-if="activity == 3" class="intabs">

      </div>
    </div>

  </div>
</template>

<script>
import API from "@/API";
import instance from  "@/axios"
import storage from "@/storage";
import Cataloglite from "@/components/catalogs/cataloglite.vue";
import Orders from "@/components/Orders/Orders.vue";


export default {

  name: "Admin",
  components: {Orders, Cataloglite},

  mounted() {
    this.checktoken();
  },

  data(){
    return{
      activity: 1,
      catalog: 1,
      authen: false,
      response_status:"",
      loading: false,
    }
  },

  methods:{
    setActivity(act)
    {
      this.activity = act;
    },
    setCatalog(act)
    {
      this.catalog = act;
    },
    checktoken()
    {
      let request = {
        token: storage.token_get()
      }
      let uri = `user/checktoken`
      API.post(uri, request).then(response=>{
        if(response.data.status == 200){
          //console.log(response.data)
          this.authen = true;
        }
      });
    },
    showstatus()
    {
      setTimeout(()=>{ this.response_status = ""; this.loading = false; },3000)
    },
    auth()
    {
      this.loading = true;
      let login = document.querySelector('#login').value;
      let password = document.querySelector('#password').value;
      let request = `user/?username=${login}&password=${password}`;
      instance.get(request).then(response=>{
        console.log(response.data)
        if(response.data.status == 200)
        {
          this.authen = true
          storage.token_set(response.data.data);

        }else{
          console.log(response.data)
          switch(response.data.status) {
            case "403": case 403:
              this.response_status = "Доступ запрещен!";
              break;
            default:
              this.response_status = "Неизвестная ошибка!";
              break;
          }
          this.showstatus();
        }
      })
    }
  }
}
</script>

<style scoped>

.CatalogTab{
  width: 100%;
  height: 100%;
  margin-top: 64px;
  border-collapse: collapse;
}

.CatalogTab td
{
  vertical-align: top;
}

.mt-64{
  margin-top:64px;
}

.user_info
{
  /*position: absolute;
  right: 16px;
  top: 16px;*/
  width: 256px;
  height: 48px;
  background: #eee;
  color: #222;
  font-size: 12px;
  padding-top: 8px;
  margin-left: auto;
  margin-right: 24px;
  border-radius: 6px;
  box-shadow: 0px 0px 15px rgba(10,10,10,0.125);
  border: 1px solid #ddd;
}

.tabs{
  width: 128px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #ccc;
  color: #222;
  border-radius: 6px 6px 0 0;
  box-shadow: 0px -10px 15px rgba(10,10,10,0.125);
  border: 1px solid #ddd;
  border-bottom: none;
  cursor: pointer;
  margin-right: 1px;
}

.cattabs{
  width: 128px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  background: #ccc;
  color: #222;
  border-radius: 6px 0 0 6px;
  /*box-shadow: 0px -10px 15px rgba(10,10,10,0.125);*/
  border: 1px solid #ddd;
  border-right: none;
  cursor: pointer;
  margin-top: 4px;
}

.ml-it{
  margin-left: 8px;
}
.tabs:hover{
  background: #eee;
}

.active
{
  background: #eee;
  color: #222;
  box-shadow: 0px -5px 10px rgba(10,10,10,0.075);
  border: 1px solid #ddd;
  border-bottom: none;
}

.catactive
{
  background: #eee;
  color: #222;
  /*box-shadow: 0px -5px 10px rgba(10,10,10,0.075);*/
  border: 1px solid #ddd;
  border-right: none;
}

.intabs
{
  width: 98vw;
  height: 85vh;
  background: #eee;
  color: #222;
  border-radius: 6px;
  box-shadow: 0px 0px 15px rgba(10,10,10,0.125);
  border: 1px solid #ddd;
  margin-top: -64px;
}

.incattabs
{
  width: 100%;
  height: 100%;
  background: #eee;
  color: #222;
  border: 1px solid #ddd;
  /*border-radius: 6px;
  margin-top: -158px;
  margin-left: calc(8vw - 18px);*/
}

.winauth
{
  width: 320px;
  height: 240px;
  position: absolute;
  left: 50%;
  top: 50%;
}

.inwinauth
{
  width: 320px;
  height: 240px;
  position: relative;
  left: -50%;
  top: -50%;
  background: #eee;
  color: #222;
  border-radius: 6px;
  box-shadow: 0px 0px 15px rgba(10,10,10,0.125);
  border: 1px solid #ddd;
}

input
{
  height: 32px;
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 2px;
  outline: none;
  color: #5648ed;
  position: relative;
  width:90%;
  text-align: center;
}

.authtitle{
  height: 48px;
  text-align: center;
  color: #222;
  margin-top: 16px;
  font-size: 12px;
}

.r_status
{
  background: rgba(86,72,237,0.5);
  box-shadow: 0px 0px 15px rgba(10,10,10,0.125);
  border: 1px solid #ddd;
  color: #eee;
  text-align: center;
  height: 24px;
  line-height: 24px;
  width: 100%;
  border-radius: 6px;
  margin-top:8px;
  font-size: 12px;

  width: 320px;
  position: relative;
  left: -50%;
  top: -50%;
}

.btn
{
  width: 90%;
  height: 34px;
  margin: auto;
  margin-top: 16px;
  border-radius: 6px;
}

.anidiv{
  background: linear-gradient(0.25turn, #5648ed,  #eee,#eee,#eee,#eee,#eee,#eee, #eee,  #eee,#eee,#eee,#eee,#eee,#eee, #eee, #5648ed);
  background-position: 0 0;
  animation: backanim 1s infinite;
}

@keyframes backanim {
  100% {
    background-position: left 530px top 0px;
  }
}

</style>