class storage
{
    static ls = localStorage;
    static token_set(token)
    {
        this.ls.setItem("token", token);
    }

    static token_get()
    {
        return this.ls.getItem("token");
    }
}

export default storage;