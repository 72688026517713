<template>
  <div class="CatalogLiteContent">

    <div class="d-fl">

      <div class="menu" :class="{active: isActiveMenu}">
        <img src="@/assets/logo.png" class="header_img" alt="" @click="mainpage()">
        <ul class="accordion">
          <li v-for="group in groups" :key="group" @click="setSelect(group.id); this.brushesactiv=false">
            <div class="menu-item" :class="{item_active: (select_id == group.id)}">{{ group.name }}</div>
            <ul v-if="select_id == group.id">
              <li v-for="sub in group.subgroup" @click="setSubGroup(group, sub.id);this.brushesactiv=false">
                <div class="inmenu-item" :class="{sitem_active: (select_sub == sub.id) }">{{ sub.name }}</div>
              </li>
            </ul>
          </li>
          <li @click="brushesactiv = true; isActiveMenu = false; setSelect(-2);">
            <div class="menu-item" :class="{item_active: (select_id == -2)}" >ЭЛЕКТРОЩЁТКИ </div>
          </li>
        </ul>
      </div>
      <div class="content">

        <catalogbrushes v-if="brushesactiv" />

        <input v-if="!brushesactiv" type="text" class="inputs" style="width:256px;" placeholder="ПОИСК" @keyup="search">

        <div v-if="view == 'grid' && !brushesactiv" class="grids">
            <catalog-lite-item
                v-for="item in items"
                :params="item"
                :function="{getItem:getItem, window_show_item:window_show_item}"
                :key="item"
                class="product"
                :class="{fst :items.indexOf(item) < 3}"
                ref="productElements"
            />
          </div>
      </div>
    </div>
      <button class="menubtn" type="button" @click="openMenu()">≡</button>

  </div>

  <window :params="window_params" :item="item"/>
  <in-cart/>

</template>

<script>
import API from "@/API";
import Window from "@/components/Window.vue";
import frmGroup from "@/components/catalogs/frmGroup.vue";
import frmItem from "@/components/catalogs/frmItem.vue";
import frmCatalogItem from "@/components/catalogs/frmCatalogItem.vue";
import gallery from "@/components/catalogs/gallery.vue";
import CatalogLiteItem from "@/components/catalogs/catalogLiteItem.vue";
import InCart from "@/components/inCart.vue";
import Catalogbrushes from "@/components/catalogs/catalogbrushes.vue";
export default {
  name: "cataloglite",
  components: {Catalogbrushes, InCart, CatalogLiteItem, Window},
  props:{
    params:{
      type:Object,
      required:true,
      default:{
        access: true
      }
    }
  },
  data(){
    return{
        srchtext:"",
        brushesactiv: false,
      groups:[],
      view: 'grid',
      subgroups:0,
      items:[],
      subname:'',
      select_id:0,
      select_group:0,
      select_sub:0,
      frmGrp: frmGroup,
      frmCatItm: frmCatalogItem,
      frmGallery: gallery,
      isActiveMenu: false,
      frmparam: {
        name: '',
        placeholder: 'Название новой группы',
        click: this.addnewgroup
      },
      frmsubparam: {
        name: '',
        placeholder: 'Название новой подгруппы',
        click: this.addnewsubgroup
      },
      frmitemparam: {
          name: '',
          click: this.addnewitem
      },
      frmgallery: {
        name: '',
        click: this.addnewitem
      },
      window_params:{width:320, height:240, show:false},
      item:{},
      searchitems:[]
    }
  },
  mounted() {
    this.getData();

   //this.handleScroll(); // Инициализируем при загрузке
  },
  methods:{
    openMenu() {
      /*let menu = document.querySelector('.menu')
      console.log(menu)
      menu.classList.toggle('active')*/
      this.isActiveMenu = !this.isActiveMenu;
    },
    handleScroll() {
      const windowHeight = window.innerHeight;
      let elms = document.querySelectorAll(".product")
      elms.forEach(el => {
        const elementPosition = el.getBoundingClientRect().top;
        const opacity = 1 - Math.max(0, elementPosition / windowHeight - 0.2);
        el.style.opacity = opacity;
        const pshow = 1 - Math.max(0, elementPosition / windowHeight - 0.8);
        if(pshow > 0.8){ el.classList.add('prodshow'); }
        if(pshow < 0.8){ el.classList.remove('prodshow'); }
      });
    },
    setSelect(grp){
      this.select_id = grp;
    },
    setSubGroup(gp, indx){
      this.select_sub = indx;
      this.items.length = 0;
      this.subname = gp.name;
      let subgrp = gp.subgroup.find( g => g.id == indx);
      this.subname += " ( "+subgrp.name+" ) ";
      this.subgroups = subgrp.id;
      this.select_group = gp.id;
      subgrp.items.forEach(sg => {
        this.items.push(sg);
      })
      try{
        //document.querySelector(".grids").removeEventListener('scroll', this.handleScroll);
      }catch(e){}
      //document.querySelector(".grids").addEventListener('scroll', this.handleScroll);
      //document.querySelector(".grids").scrollTop = 24;
      this.isActiveMenu = false;
    },
    getData(){
      let uri = 'CatalogLite/get/?name=';
      let param = {}
      API.post(uri, param).then(
          response => {
            this.groups.length = 0;
            response.data.data.forEach(g => {
              this.groups.push(g);
            })
              //console.log(this.groups)
          }

      );
    },
    search(input){
        //console.log(input.target.value);return 0;
        this.srchtext = (input != null && input.target != null) ? input.target.value : this.srchtext;
        let text = this.srchtext
        console.log(this.srchtext)
      //    .toLowerCase();

      this.searchitems.length = 0;
      this.groups.forEach(grp => {
        grp.subgroup.forEach(sub => {
          sub.items.forEach(itm => {
            this.searchitems.push(itm);
          })
        })
      })

      this.items = this.searchitems.filter(e =>
          (e.name.toLowerCase().includes(text) ||
              e.area.toLowerCase().includes(text) ||
              e.mark.toLowerCase().includes(text) ||
              e.drawing.toLowerCase().includes(text))
      );
    },
    getItem(id){
      let uri = 'CatalogLite/getitem/?id='+id;
      let param = {}
      API.post(uri, param).then(
          response => {
            //console.log(response.data)
            if(response.data.message == 'done'){
              let item = response.data.data[0];
              let param = {
                id: id,
                name: item.name,
                area: item.area,
                size_w: item.size_w,
                size_h: item.size_h,
                size_d: item.size_d,
                mark: item.mark,
                drawing: item.drawing,
                subgroup: item.subgroup,
                photo_path: item.photo_path,
                click: this.updateitem
              }
              this.subgroups = item.subgroup;
              this.window_edit_item(item.subgroup, param);
            }
          }
      );
    },

    window_show_item(item){
      this.frmitemparam.subgroup = this.subgroups;
      this.window_params = {
        width: 743,
        height: 502,
        show: true,
        content: this.frmCatItm,
        frmparam: item
      }
      //this.item = item;
    },
    window_gallery(){
      this.frmitemparam.subgroup = this.subgroups;
      this.window_params = {
        width: 820,
        height: 820,
        show: true,
        content: this.frmGallery,
        frmparam: {
          dnd: true,
          returning: false,
          name: '',
          action: undefined,
        }
      }
      //this.item = item;
    },
    mainpage(){
      window.location.href = './';
    },
  }
}
</script>

<style scoped>

.menu{
  background: #fff;
  width: 260px;
  height: 100vh;
  box-shadow: 0 2px 6px rgba(47,43,65,.14),0 0 transparent,0 0 transparent;
  z-index: 99;
  position: fixed;
  left: 0;
  top: 0;
}

.content{
  background: #f0f0f0;
  width: calc(100vw - 240px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  margin-left: auto;
}

.header_img {
  width: 140px;
  margin-top: 8px;
}

.maintab{
  width: 100%;
  border-collapse: collapse;
}

.maintab tr td:first-child{
  width: 240px;
  background: #fff;
}

.maintab tr td{
  vertical-align: top;
}

.itemtab{
  width: 98%;
  margin: 1%;
/*  border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0 4px; /* Горизонтальное и вертикальное расстояние между ячейками */
}

.itemtab tr th{
  height: 48px;
  background: #bbb;
  color: #111;
}

.itemtab tr th:first-child{
  border-radius: 6px 0 0 0;
}
.itemtab tr th:last-child{
  border-radius: 0 6px 0 0;
}

.itemtab tr{
    transition-property: background-color, box-shadow, transform;
    transition:  0.5s ease;
}

.itemtab tr:hover{
    box-shadow: 0px 0px 5px rgba(10,30,200,0.95);
    /*transform: scale(1.02, 1.02);*/
}

.itemtab tr td{
  height: 32px;
  background: #dddddd;
  padding-top: 12px;
}

.itemtab tr td:first-child{
  border-radius: 6px 0 0 6px;
}
.itemtab tr td:last-child{
  border-radius: 0 6px 6px 0;
}

.selectel{

  margin: 16px;
  padding: 8px;
  border-radius: 6px;
  background: #dddddd;
  color: #111111;
}

/* Стили для внешнего списка */
ul.accordion {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 32px;
}

ul.accordion > li {
  /*border-bottom: 1px solid #fff;  Разделительные линии между элементами */
}

/* Стили для заголовков элементов аккордеона */
ul.accordion > li {
  font-size: 18px;
  //text-align: left;
  display: block;
  padding: 0;/*8px 0px;*/
  color: #333;
  border-radius: 8px;
  /*text-decoration: none;
  background-color: #fff;*/
  transition: background-color 0.3s ease;
}

.menu-item{
  height: 32px;
  line-height: 32px;
  margin: auto;
  border-radius: 8px;
  text-align: left;
  padding: 0 16px;
  margin-top: 4px;
  width: 65%;
}

.menu-item:hover{
  background-color: #f0f0f0;/*#e9e9e9;*/
  cursor: pointer;
}

.inmenu-item{
  min-height: 32px;
  /*line-height: 32px;*/
  margin: auto;
  border-radius: 8px;
  text-align: left;
  padding: 0px 16px;
  width: 71%;
  margin-left: 6px;
}

.inmenu-item:hover{
  background-color: #f0f0f0;/*#e9e9e9;*/
  color: #222222;
  cursor: pointer;
}

.item_active{
  background-color: #f0f0f0;
}

.sitem_active{
  color: #eee;
  background-color: #3366ff;
  box-shadow: 0 2px 6px rgba(47,43,65,.14),0 0 transparent,0 0 transparent;
}

/* Стили для внутреннего списка */
ul.accordion li ul {
  list-style: none;
  padding: 0;
  /*display: none;  Скрыть внутренний список по умолчанию */
  margin: 0; /* Удалить внешние отступы */
}

ul.accordion li ul li {
  padding: 2px 0px;
  border-radius: 8px;
  min-height: 32px;
  /* background-color: #fff; */
  /* border-top: 1px solid #f0f0f0; */
  transition-property: background-color, box-shadow, transform;
  transition: 0.3s ease;
  text-align: left;
  font-size: 14px;
  padding-left: 24px;
  transition-property: background-color, box-shadow, transform;
  transition: 0.3s ease;
}

/*ul.accordion li ul li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}*/

.btnImg{
  margin: 0 8px;
  transition-property: transform;
  transition:  0.3s ease;
}

.btnImg:hover{
  transform: scale(1.5);
}

.grids{
  width: 90%;
  height: 73vh;
  margin: auto;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}

#app{
  overflow-y: hidden;
}
.CatalogLiteContent
{
    background: #f0f0f0;
}

.product {
  /*opacity: 0;  Изначально скрыто
  margin-top: 300px;*/
  transition: opacity, margin-top 0.75s; /* Плавное изменение прозрачности */
  /* Другие стили для товара */
}

.prodshow{
  margin-top: 24px;
}

.fst{
  margin-top: 16px !important;
  /*opacity: 0.5;  Изначально скрыто */
}
.menubtn {
  display: none;
}
@media screen and (max-width: 1050px) {
  .CatalogLiteContent {
    min-height: 559px;
  }
  .menu{
    /*display: none;*/
    margin-left: -264px;
    transition: margin-left 0.5s ease;
  }
  .active{
    /*display: block !important;*/
    margin-left: 0px;
  }
  .menubtn {
    display: block;
    background: #ddd;
    border: 1px solid #ccc;
    border-radius: 16px;
    outline: none;
    position: fixed;
    bottom: 32px;
    left: 16px;
    width: 48px;
    height: 48px;
    text-align: center;
    z-index: 999;
    font-size: 30px;
    color: #224499;
    box-shadow: 0px 0px 15px rgba(10,10,10,0.25);
  }
  .inputs {
    margin: initial;
    width: 97% !important;
    margin-top: 8px;
  }
  .headtab {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .menutab {
    position: relative;
  }
  .menulisttab {
    display: none;
    position: absolute;
  }
  .menulisttab.active {
    display: block;
  }
  .maintab {
    width: 98%;
    margin: 0 auto;
  }
  .maintab tr td:first-child {
    width: 100%;
    z-index: 1;
  }
  ul.accordion li ul {
    /*display: flex;
    overflow-y: initial;
    overflow-x: auto;*/
  }
  .grids {
    display: flex;
    width: 96%;
    overflow-y: initial;
    overflow-x: auto;
    min-height: 433px;
    height: calc(100vh - 64px);
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: flex-start;
    gap: 10px;
    margin-left: 2px;
  }
  .btnImg:hover{
    transform: none;
  }
  .fst{
    margin-top: 0 !important;
    opacity: 1 !important;
  }
  .cardstab {
    position: absolute;
    top: 0;
    max-width: 100%;
  }
  .product {
    transition: initial;
  }
  .prodshow {
    margin-top: 0;
    opacity: 1 !important;
  }
  .content{
      background: #f0f0f0;
      width: 98vw;
      height: 100vh;
      display: flex;
      flex-direction: column;
  }
}
</style>
