<template>
      <div class="content_brushes">

        <div class="outfilter" v-if="items.length != 0">
          <div class="filter">
            <div class="litegroup">

            <div class="d-fl fl-clm m-8 infiltr">
              <label for="size_w" class="mt-16">Ширина</label>
              <select name="size_w"
                      id="f_size_w"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;margin-bottom: 16px;"
                      v-model="filter_size_w"
                      @change="main_filter()"
              >
                <option v-for="m in filters.size_w" :value="m" :key="m">{{m}}</option>
              </select>
            </div>

            <div class="d-fl fl-clm m-lr infiltr">
              <label for="size_h" class="mt-16">Высота</label>
              <select name="size_h"
                      id="f_size_h"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_size_h"
                      @change="main_filter()"
              >
                <option v-for="m in filters.size_h" :value="m" :key="m">{{m}}</option>
              </select>
            </div>

            <div class="d-fl fl-clm m-lr infiltr">
              <label for="size_d" class="mt-16">Глубина</label>
              <select name="size_d"
                      id="f_size_d"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_size_d"
                      @change="main_filter()"
              >
                <option v-for="m in filters.size_d" :value="m" :key="m">{{m}}</option>
              </select>
            </div>

            </div>
            <div class="litegroup">
            <div class="d-fl fl-clm m-lr infiltr">
              <label for="mark" class="mt-16">Марка</label>
              <select name="mark"
                      id="f_mark"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_mark"
                      @change="main_filter()"
              >
                <option v-for="m in filters.mark" :value="m" :key="m">{{m}}</option>
              </select>
            </div>
            <div class="d-fl fl-clm m-lr infiltr">
              <label for="type" class="mt-16">Тип щетки</label>
              <select name="type"
                      id="f_type"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_type"
                      @change="main_filter()"
              >
                <option v-for="m in filters.type" :value="m" :key="m">{{m}}</option>
              </select>
            </div>
            <div class="d-fl fl-clm m-lr infiltr">
              <label for="wire" class="mt-16">Провод</label>
              <select name="wire"
                      id="f_wire"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_wire"
                      @change="main_filter()"
              >
                <option v-for="m in filters.wire" :value="m" :key="m">{{m}}</option>
              </select>
            </div>
            </div>
              <div class="litegroup">
            <div class="d-fl fl-clm m-lr infiltr">
              <label for="tip" class="mt-16">Наконечник</label>
              <select name="tip"
                      id="f_tip"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_tip"
                      @change="main_filter()"
              >
                <option v-for="m in filters.tip" :value="m" :key="m">{{m}}</option>
              </select>
            </div>
            <div class="d-fl fl-clm m-lr infiltr">
              <label for="verlay" class="mt-16">Накладка</label>
              <select name="verlay"
                      id="f_overlay"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_overlay"
                      @change="main_filter()"
              >
                <option v-for="m in filters.overlay" :value="m" :key="m">{{m}}</option>
              </select>
            </div>
            <div class="d-fl fl-clm m-lr infiltr">
              <label for="insulation" class="mt-16">Изоляция</label>
              <select name="insulation"
                      id="f_insulation"
                      class="inputs mt-0"
                      style="min-width:64px;color:#222;"
                      v-model="filter_insulation"
                      @change="main_filter()"
              >
                <option v-for="m in filters.insulation" :value="m" :key="m">{{m}}</option>
              </select>
            </div>
              </div>
            <!--<input type="text" class="inputs" style="width:256px;margin-bottom: 32px" placeholder="ПОИСК" @keyup="search">-->
          </div>
        </div>

        <div class="with_table" v-if="items.length != 0">

          <table class="itemtab">
            <tr>
              <th>Арикул</th>
              <th>Марка</th>
              <th>Рамер</th>
              <!--<th>Чертеж</th>-->
              <th>Тип щетки</th>
              <th>Провод</th>
              <th>Тип наконечника</th>
              <th>Тип накладки</th>
              <th>Изоляция</th>
              <!--<th>Ширина</th>
              <th>Высота</th>
              <th>Глубина</th>
              -->
            </tr>
            <tr v-for="item in filtered" :key="item" @click="add(item)">
              <td id="brushes">{{item.articule}}</td>
              <td id="brushes">{{ item.mark }}</td>
              <td id="brushes">{{ item.size }}</td>
              <td id="brushes">{{ item.type }}</td>
              <td id="brushes">{{ item.wire }}</td>
              <td id="brushes">{{ item.tip }}</td>
              <td id="brushes">{{ item.overlay }}</td>
              <td id="brushes">{{ item.insulation }}</td>
            </tr>
          </table>

          <div class="inmobtab">

            <div v-for="item in filtered" :key="item" class="inmobitem">
              <div class="litegroup init">
                <div ><h5>Артикул</h5><div class="cleared">{{item.articule}}</div></div>
                <div ><h5>Марка</h5><div class="cleared">{{ item.mark }}</div></div>
                <div ><h5>Размер</h5><div class="cleared">{{ item.size }}</div></div>
              </div>
              <div class="litegroup init">
                <div ><h5>Тип щетки</h5><div class="cleared">{{ item.type }}</div></div>
                <div><h5>Провод</h5><div class="cleared">{{ item.wire }}</div></div>
                <div><h5>Наконечник</h5><div class="cleared">{{ item.tip }}</div></div>
              </div>
              <div class="litegroup init">
                <div><h5>Накладка</h5><div class="cleared">{{ item.overlay }}</div></div>
                <div><h5>Изоляция</h5><div class="cleared">{{ item.insulation }}</div></div>
                <img id="brushes" class="btnImg" :class="{borderbtn: showaddbtn}" @click="add(item)" :src="require('@/assets/odmin/cart.svg')">
              </div>
            </div>

          </div>

        </div>
      </div>

</template>

<script>
import API from "@/API";
import Window from "@/components/Window.vue";
import frmGroup from "@/components/catalogs/frmGroup.vue";
import frmItem from "@/components/catalogs/frmItem.vue";
import frmCatalogItem from "@/components/catalogs/frmCatalogItem.vue";
import gallery from "@/components/catalogs/gallery.vue";
import CatalogLiteItem from "@/components/catalogs/catalogLiteItem.vue";
import InCart from "@/components/inCart.vue";
import cart from "@/cart";
export default {
  name: "catalogbrushes",
  components: {InCart, CatalogLiteItem, Window},
  props:{
    params:{
      type:Object,
      required:true,
      default:{
        access: true
      }
    }
  },
  data(){
    return{
      filter_mark: '-',
      filter_insulation: '-',
      filter_overlay: '-',
      filter_size_w: '-',
      filter_size_h: '-',
      filter_size_d: '-',
      filter_tip: '-',
      filter_type: '-',
      filter_wire: '-',
      filters:{},
      filtered:[],
      brushes: false,
      groups:[],
      view: 'grid',
      subgroups:0,
      items:[],
      subname:'',
      select_id:0,
      select_group:0,
      frmGrp: frmGroup,
      frmCatItm: frmCatalogItem,
      frmGallery: gallery,
      isActiveMenu: false,
      frmparam: {
        name: '',
        placeholder: 'Название новой группы',
        click: this.addnewgroup
      },
      frmsubparam: {
        name: '',
        placeholder: 'Название новой подгруппы',
        click: this.addnewsubgroup
      },
      frmitemparam: {
          name: '',
          click: this.addnewitem
      },
      frmgallery: {
        name: '',
        click: this.addnewitem
      },
      window_params:{width:320, height:240, show:false},
      item:{},
      searchitems:[]
    }
  },
  mounted() {
    this.getData();

   //this.handleScroll(); // Инициализируем при загрузке
  },
  methods:{
    setFilters(){
      this.filter_mark = this.filters.mark[0];
      this.filter_insulation = this.filters.insulation[0];
      this.filter_overlay = this.filters.overlay[0];
      this.filter_size_w = this.filters.size_w[0];
      this.filter_size_h = this.filters.size_h[0];
      this.filter_size_d = this.filters.size_d[0];
      this.filter_tip = this.filters.tip[0];
      this.filter_type = this.filters.type[0];
      this.filter_wire = this.filters.wire[0];
    },
    getData(){
      let uri = 'Brushes/get/';
      let param = {}
      API.post(uri, param).then(
          response => {
            this.items.length = 0;
            response.data.data[0].forEach(g => {
              this.items.push(g);
              this.filtered.push(g);
            })
            /*let mark = response.data.data[1].mark;
            let insulation = response.data.data[1].insulation;
            let overlay = response.data.data[1].overlay;
            let size_w = response.data.data[1].size_w;
            let size_h = response.data.data[1].size_h;
            let size_d = response.data.data[1].size_d;
            let tip = response.data.data[1].tip;
            let type = response.data.data[1].type;
            let wire = response.data.data[1].wire;*/

            this.filters = response.data.data[1];
            this.setFilters();
          }

      );
    },
    add(itm){
      let item = JSON.parse(JSON.stringify(itm));
      item.count = 1;
      item.id = itm.articule;
      item.name = itm.drawing;
      cart.plus(item);
      this.showaddbtn = true;
    },
    main_filter(){
      let filtered = this.items.filter(item =>
          (
              ((this.filter_size_w !== '-' && this.filter_size_w.trim() !== '' ) ? item.size_w === this.filter_size_w : item.size_w !== this.filter_size_w) &&
              ((this.filter_size_h !== '-' && this.filter_size_h.trim() !== '') ? item.size_h === this.filter_size_h : item.size_h !== this.filter_size_h) &&
              ((this.filter_size_d !== '-' && this.filter_size_d.trim() !== '') ? item.size_d === this.filter_size_d : item.size_d !== this.filter_size_d) &&
              ((this.filter_mark !== '-' && this.filter_mark.trim() !== '') ? item.mark === this.filter_mark : item.mark !== this.filter_mark) &&
              ((this.filter_insulation !== '-' && this.filter_insulation.trim() !== '') ? item.insulation === this.filter_insulation : item.insulation !== this.filter_insulation) &&
              ((this.filter_overlay !== '-' && this.filter_overlay.trim() !== '') ? item.overlay === this.filter_overlay : item.overlay !== this.filter_overlay) &&
              ((this.filter_tip !== '-' && this.filter_tip.trim() !== '') ? item.tip === this.filter_tip : item.tip !== this.filter_tip) &&
              ((this.filter_type !== '-' && this.filter_type.trim() !== '') ? item.type === this.filter_type : item.type !== this.filter_type) &&
              ((this.filter_wire !== '-' && this.filter_wire.trim() !== '') ? item.wire === this.filter_wire : item.wire !== this.filter_wire)
          )
      )

      this.filtered.length = 0;
      this.filters.size_w.length = 0;
      this.filters.size_h.length = 0;
      this.filters.size_d.length = 0;
      this.filters.mark.length = 0;
      this.filters.insulation.length = 0;
      this.filters.overlay.length = 0;
      this.filters.tip.length = 0;
      this.filters.type.length = 0;
      this.filters.wire.length = 0;

      this.filters.size_w.push('-');
      this.filters.size_h.push('-');
      this.filters.size_d.push('-');
      this.filters.mark.push('-');
      this.filters.insulation.push('-');
      this.filters.overlay.push('-');
      this.filters.tip.push('-');
      this.filters.type.push('-');
      this.filters.wire.push('-');

      filtered.forEach(fil => {
        if(fil !== '-') {
          this.filtered.push(fil);
          if (this.filters.size_w.indexOf(fil.size_w) === -1) this.filters.size_w.push(fil.size_w);
          if (this.filters.size_h.indexOf(fil.size_h) === -1) this.filters.size_h.push(fil.size_h);
          if (this.filters.size_d.indexOf(fil.size_d) === -1) this.filters.size_d.push(fil.size_d);
          if (this.filters.mark.indexOf(fil.mark) === -1) this.filters.mark.push(fil.mark);
          if (this.filters.insulation.indexOf(fil.insulation) === -1) this.filters.insulation.push(fil.insulation);
          if (this.filters.overlay.indexOf(fil.overlay) === -1) this.filters.overlay.push(fil.overlay);
          if (this.filters.tip.indexOf(fil.tip) === -1) this.filters.tip.push(fil.tip);
          if (this.filters.type.indexOf(fil.type) === -1) this.filters.type.push(fil.type);
          if (this.filters.wire.indexOf(fil.wire) === -1) this.filters.wire.push(fil.wire);
        }
      })

      /*console.log(this.filtered);

      filter_size_w:'',
      filter_size_h:'',
      filter_size_d:'',
      filter_mark:'',
      filter_insulation:'',
      filter_overlay:'',
      filter_tip:'',
      filter_type:'',
      filter_wire:'',
       */
    },
    addnewitem(item){
          let uri = 'CatalogLite/additem/';
          let param = {
              name:item.name,
              area:item.area,
              size_w:item.size_w,
              size_h:item.size_h,
              size_d:item.size_d,
              mark:item.mark,
              drawing:item.drawing,
              subgroup:item.subgroup,
              photo_path:item.photo_path
          }
          API.post(uri, param).then(
              response => {
                  //console.log(response.data)
                  if(response.data.message == 'done'){
                      let g = param;
                      g.id = response.data.data.DB;
                      this.groups.find(grp => grp.id == this.select_group).subgroup.find(sg => sg.id == this.subgroups).items.push(g);
                      this.items.push(g);
                      this.window_params.show = false;
                  }
              }
          );
      },
    updateitem(item){
      let uri = 'CatalogLite/updateitem/';
      let param = {
        id: item.id,
        name:item.name,
        area:item.area,
        size_w:item.size_w,
        size_h:item.size_h,
        size_d:item.size_d,
        mark:item.mark,
        drawing:item.drawing,
        subgroup:item.subgroup,
        photo_path:item.photo_path
      }

      API.post(uri, param).then(
          response => {
            if(response.data.message == 'done'){

              let g = response.data.data;
              let itm = this.items.find(itm => itm.id === g.id);

              itm.name = g.name;
              itm.area = g.area;
              itm.size_w = g.size_w;
              itm.size_h = g.size_h;
              itm.size_d = g.size_d;
              itm.mark = g.mark;
              itm.drawing = g.drawing;
              itm.subgroup = g.subgroup;
              itm.photo_path = g.photo_path;
              //this.items.push(g);
              this.window_params.show = false;/**/
            }
          }
      );
    },
    window_add_group(){
      this.window_params = {
        width: 320,
        height: 120,
        show: true,
        content: this.frmGrp,
        frmparam: this.frmparam
      }
    },
    window_add_subgroup(grp){
      this.window_params = {
        width: 320,
        height: 120,
        show: true,
        content: this.frmGrp,
        frmparam: this.frmsubparam,
          group: grp
      }
    },
    window_add_item(grp){
        this.frmitemparam.subgroup = this.subgroups;
        this.frmitemparam.group = grp;
        this.window_params = {
            width: 320,
            height: 512,
            show: true,
            content: frmItem,
            frmparam: this.frmitemparam,
            group: grp
        }
    },
    window_edit_item(grp, parram){
      this.frmitemparam.subgroup = this.subgroups;
      this.frmitemparam.group = grp;
      this.window_params = {
        width: 320,
        height: 512,
        show: true,
        content: frmItem,
        frmparam: parram,
        group: grp
      }
    },
    window_show_item(item){
      this.frmitemparam.subgroup = this.subgroups;
      this.window_params = {
        width: 743,
        height: 502,
        show: true,
        content: this.frmCatItm,
        frmparam: item
      }
      //this.item = item;
    },
  }
}
</script>

<style scoped>

.infiltr{
  display:inline-block;
  padding: 16px 0;
}

.with_table{
  padding: 16px;
  background: #fff;
  width: 88%;
  margin: auto;
  margin-top: 0;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(47,43,65,.14), 0 0 transparent, 0 0 transparent;
}

.outfilter{
  width: 100%;
  height: 128px;
  background: #f0f0f0;
  display: flex;
  position: sticky;
  top: 0px;
  margin-bottom: 16px;
  margin-top: 0px;
  z-index: 9;
}

.filter{
  width: 89%;
  margin: auto;
  height: 96px;
  background: #fff;
  display: flex;
  padding-left: 16px;
  box-shadow: 0 2px 6px rgba(47,43,65,.14), 0 0 transparent, 0 0 transparent;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-top: 16px;
}

.content_brushes{
  background: #f0f0f0;
  width: calc(100vw - 240px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.itemtab{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  border-collapse: collapse;/*
  border-collapse: separate; */
  /*border-spacing: 0 4px;  Горизонтальное и вертикальное расстояние между ячейками */
}

.itemtab tr th{
  height: 56px;
  background: #fff;
  color: #111;
  position: sticky;
  top: 128px;
}

.itemtab tr th:first-child{
  border-radius: 6px 0 0 0;
}
.itemtab tr th:last-child{
  border-radius: 0 6px 0 0;
}

.itemtab tr:first-child td {
  border-top: none;
}

.itemtab tr td {
  border-top: 1px solid #aaa;
}

.itemtab tr{
  transition-property: background-color, box-shadow, transform;
  transition:  0.2s ease;
}

.itemtab tr:hover{
  box-shadow: 0px 0px 5px rgba(10,30,200,0.95);
  transform: scale(1.001, 1.001);/**/
  cursor: pointer;
}

.itemtab tr td{
  height: 32px;
  background: #fff;
  line-height: 32px;
}

.itemtab tr td:first-child{
//border-radius: 6px 0 0 6px;
  border-radius: 0;
}
.itemtab tr td:last-child{
//border-radius: 0 6px 6px 0;
  border-radius: 0;
}

.selectel{

  margin: 16px;
  padding: 8px;
  border-radius: 6px;
  background: #dddddd;
  color: #111111;
}

/* Стили для внешнего списка */
ul.accordion {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.accordion > li {
  /*border-bottom: 1px solid #fff;  Разделительные линии между элементами */
}

/* Стили для заголовков элементов аккордеона */
ul.accordion > li {
  font-size: 18px;
//text-align: left;
  display: block;
  padding: 0;/*8px 0px;*/
  color: #333;
  /*text-decoration: none;*/
  background-color: #fff;
  transition: background-color 0.3s ease;
}

ul.accordion > li:hover {
  background-color: #f0f0f0;/*#e9e9e9;*/
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(10,10,10,0.125);
}

/* Стили для внутреннего списка */
ul.accordion li ul {
  list-style: none;
  padding: 0;
  /*display: none;  Скрыть внутренний список по умолчанию */
  margin: 0; /* Удалить внешние отступы */
}

ul.accordion li ul li {
  padding: 8px 15px;
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  transition-property: background-color, box-shadow, transform;
  transition: 0.3s ease;
  text-align: left;
  font-size: 14px;
  padding-left: 24px;
  transition-property: background-color, box-shadow, transform;
  transition:  0.3s ease;
}

ul.accordion li ul li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
}

.inmobtab{
  display: none;
}

@media screen and (max-width: 1050px) {

  .content_brushes{
    background: #f0f0f0;
    width: 96%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
  }

  .litegroup{
    flex-direction: column;
    margin-right: 6px;
  }

  .filter{
    width: 89%;
    margin: auto;
    height: 158px;
    background: #fff;
    display: flex;
    padding-left: 16px;
    box-shadow: 0 2px 6px rgba(47,43,65,.14), 0 0 transparent, 0 0 transparent;
    border-radius: 8px;
    margin-bottom: 16px;
    margin-top: 16px;
  }
  .outfilter{
    width: 100%;
    height: 166px;
    background: #f0f0f0;
    display: flex;
    position: sticky;
    top: 0px;
    margin-bottom: 16px;
    margin-top: 0px;
    z-index: 9;
  }
  .infiltr{
    display:inline-block;
    margin: auto;
    padding: initial !important;
    height: 54px;
  }
  .inmobtab{
    display: block;
  }
  .inmobitem{
    width: 100%;
    border-radius: 16px;
    display: flex;
    padding-bottom: 12px;
    margin-top: 8px;
    background: #fff;
    box-shadow: 0 2px 6px rgba(47,43,65,.14), 0 0 transparent, 0 0 transparent;
  }
  .itemtab{
    display: none;
  }
  .init{
    width: 30%;
  }
  .cleared{
    height: 16px;
  }
  h5{
    padding: 4px;
  }
  .with_table{
    padding: 16px;
    width: 92%;
    margin: auto;
    margin-top: 0;
    background: initial;
    box-shadow: initial;
  }
}

.btnImg{
  margin: 0 8px;
  transition-property: transform;
  transition:  0.3s ease;
}

.btnImg:hover{
  transform: scale(1.5);
}

.grids{
  width: 90%;
  height: 73vh;
  margin: auto;
  margin-top: 16px;
  overflow-y: auto;
  overflow-x: hidden;
}

#app{
  overflow-y: hidden;
}
.CatalogLiteContent
{
    background: #f0f0f0;
}

.product {
  /*opacity: 0;  Изначально скрыто
  margin-top: 300px;*/
  transition: opacity, margin-top 0.75s; /* Плавное изменение прозрачности */
  /* Другие стили для товара */
}

.prodshow{
  margin-top: 24px;
}

.fst{
  margin-top: 16px !important;
  /*opacity: 0.5;  Изначально скрыто */
}
.menubtn {
  display: none;
}
@media screen and (max-width: 1050px) {
  .CatalogLiteContent {
    min-height: 559px;
  }
  .menubtn {
    display: block;
    margin-right: 10px;
    background: #ddd;
    border: 1px solid #ccc;
    border-radius: 6px;
    outline: none;
    position: relative;
    text-align: center;
  }
  .inputs {
    margin: initial;
    width: 100% !important;
  }
  .headtab {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .menutab {
    position: relative;
  }
  .menulisttab {
    display: none;
    position: absolute;
  }
  .menulisttab.active {
    display: block;
  }
  .maintab {
    width: 98%;
    margin: 0 auto;
  }
  .maintab tr td:first-child {
    width: 100%;
    z-index: 1;
  }
  ul.accordion li ul {
    display: flex;
    overflow-y: initial;
    overflow-x: auto;
  }
  .grids {
    display: flex;
    width: 100%;
    overflow-y: initial;
    overflow-x: auto;
    height: 433px;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: flex-start;
    gap: 10px;
  }
  .btnImg:hover{
    transform: none;
  }
  .fst{
    margin-top: 0 !important;
    opacity: 1 !important;
  }
  .cardstab {
    position: absolute;
    top: 0;
    max-width: 100%;
  }
  .product {
    transition: initial;
  }
  .prodshow {
    margin-top: 0;
    opacity: 1 !important;
  }
}
</style>
