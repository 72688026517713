<template>
  <div class="animated-div-e"><img :src="require('@/assets/electro.png')" class="wdtimg"></div>
</template>

<script>
export default {
  name: "AnimateElectro",
  data(){
    return{
      div:{}
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    this.div = document.querySelector('.animated-div-e');
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      //const div = document.querySelector('.animated-div');//this.$refs.movingDiv;
      if(this.div != null){
        const divPosition = this.div.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (divPosition <= windowHeight) {
          this.div.classList.add('move-left-e');
          this.div.classList.add('animated-div-e-show');
        } else {
          this.div.classList.remove('move-left-e');
          this.div.classList.remove('animated-div-e-show');
        }
      }
    }
  }
};
</script>

<style>
.animated-div-e {
  width: 100px;
  height: 100px;
  position: absolute;
  right: 0;
  margin-top: -182px;
  transition: transform 1.5s;
  overflow: hidden;
}
.animated-div-e-show {
  overflow: initial;
}
.move-left-e {
  transform: translateX( calc(-100vw + 96px) ); /* Двигаем влево на 100% ширины элемента */
}

.wdtimg{
  width: 512px;
}
@media screen and (max-width: 900px) {
  .wdtimg {
    width: 320px;
  }
  .animated-div-e {
    margin-top: -116px;
  }
}
</style>