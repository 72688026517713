<template>
    <div class="sticky-element">
      <mainmenu/>
    </div>
  <div class="menu-burger">
    <img src="../../assets/menu-burger.svg" alt="Меню" class="menu-burger_icon" @click="switchModal" />
  </div>
  <div class="modal" :class="{show: modal}">
    <div class="modal-content">
      <span class="modal-close" @click="switchModal">
        <img src="../../assets/close.svg" alt="Закрыть" class="menu-burger_close" />
      </span>
      <mainmenu @click="switchModal"/>
    </div>
  </div>
</template>

<script>

import Mainmenu from "@/components/salt/mainmenu.vue";
export default {
  name: "stickybar",
  components: {Mainmenu},
  data() {
    return {
      modal: false,
    }
  },
  methods: {
    switchModal() {
      this.modal = !this.modal;
    },
  }
}
</script>

<style scoped>
.sticky-element {
  position: -webkit-sticky; /* Для поддержки Safari */
  position: sticky;
  top: 0; /* Отступ сверху, когда элемент зафиксирован */
  /*background: white;*/
  height: 44px;
  border: 1px solid rgba(200,200,200,0.001);
  z-index: 10;
  color:#777;
  margin-top: 0px;
  /*box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
  box-shadow: 0 0 15px rgba(0,0,0,.25), 0 3px 1px -2px rgba(0,0,0,.2);*/
  box-shadow: none !important;
}

.navb
{
  z-index: 999;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14), 0 3px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
}
.menu-burger {
  display: none;
  margin-left: 90px;
}
.menu-burger_icon {
  width: 24px;
  height: 24px;
}
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in-out;
}
.show {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.2s ease-in-out;
}
.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  height: 100vh;
}
.modal-close {
  position: absolute;
  top: 27px;
  right: 27px;
  cursor: pointer;
}
.menu-burger_close {
  width: 20px;
  height: 20px;
}
@media screen and (max-width: 620px) {
  .sticky-element {
    display: none;
  }
  .menu-burger {
    display: block;
    position: absolute;
    right: 25px;
    top: 47px;
  }
}
@media screen and (max-width: 500px) {
  .menu-burger {
    top: 24px;
  }
}

</style>