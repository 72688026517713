<template>
  <div class="CatalogLiteContent">

    <table class="maintab">
      <tr class="menutab">
        <td class="cardstab">

          <table v-if="view == 'tab'" class="itemtab">
            <tr>
              <th>№</th>
              <th>Название</th>
              <th>Обл.прим</th>
              <th>Ширина</th>
              <th>Высота</th>
              <th>Глубина</th>
              <th>Маркировка</th>
              <th>Чертеж</th>
              <th>Группа</th>
              <th>Фото</th>
              <th style="width:128px;">Действия</th>
            </tr>
            <tr v-for="item in items" :key="item">
              <td>{{item.id}}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.area }}</td>
              <td>{{ item.size_w }}</td>
              <td>{{ item.size_h }}</td>
              <td>{{ item.size_d }}</td>
              <td>{{ item.mark }}</td>
              <td>{{ item.drawing }}</td>
              <td>{{ item.subgroup }}</td>
              <td>{{ item.photo_path }}</td>
              <td>
                <img class="btnImg" :src="require('@/assets/odmin/edit.svg')" alt="" @click="getItem(item.id)">
                <img class="btnImg" :src="require('@/assets/odmin/show.svg')" alt="" @click="window_show_item(item)">
                <img class="btnImg" :src="require('@/assets/odmin/trash.svg')" alt="">
              </td>
            </tr>
          </table>

          <div v-if="view == 'grid'" class="grids">
            <catalog-lite-item
                v-for="item in items"
                :params="item"
                :function="{getItem:getItem, window_show_item:window_show_item}"
                :key="item"
                class="product"
                ref="productElements"
            />
          </div>
        </td>
      </tr>
    </table>

  </div>

  <window :params="window_params" :item="item"/>

</template>

<script>
import API from "@/API";
import Window from "@/components/Window.vue";
import frmGroup from "@/components/catalogs/frmGroup.vue";
import frmItem from "@/components/catalogs/frmItem.vue";
import frmCatalogItem from "@/components/catalogs/frmCatalogItem.vue";
import gallery from "@/components/catalogs/gallery.vue";
import CatalogLiteItem from "@/components/catalogs/catalogLiteItem.vue";
import InCart from "@/components/inCart.vue";
export default {
  name: "catalogliteRND",
  components: {InCart, CatalogLiteItem, Window},
  props:{
    params:{
      type:Object,
      required:true,
      default:{
        access: true
      }
    }
  },
  data(){
    return{
      groups:[],
      view: 'grid',
      subgroups:0,
      items:[],
      subname:'',
      select_id:0,
      select_group:0,
      frmGrp: frmGroup,
      frmCatItm: frmCatalogItem,
      frmGallery: gallery,
      isActiveMenu: false,
      window_params:{width:320, height:240, show:false},
      item:{},
      searchitems:[]
    }
  },
  mounted() {
    this.getData();

    this.handleScroll(); // Инициализируем при загрузке
  },
  methods:{
    handleScroll() {
      /*const windowHeight = window.innerHeight;
      let elms = document.querySelectorAll(".product")
      elms.forEach(el => {
        const elementPosition = el.getBoundingClientRect().top;
        const opacity = 1 - Math.max(0, elementPosition / windowHeight - 0.2);
        el.style.opacity = opacity;
        const pshow = 1 - Math.max(0, elementPosition / windowHeight - 0.8);
        if(pshow > 0.8){ el.classList.add('prodshow'); }
        if(pshow < 0.8){ el.classList.remove('prodshow'); }
      });*/
    },
    getData(){
      let uri = 'CatalogLite/getrnd/';
      let param = {}
      API.post(uri, param).then(
          response => {
            this.items.length = 0;
            response.data.data.forEach(g => {
              this.items.push(g);
            })
              //console.log(this.groups)
          }

      );
    },
    getItem(id){
      let uri = 'CatalogLite/getitem/?id='+id;
      let param = {}
      API.post(uri, param).then(
          response => {
            //console.log(response.data)
            if(response.data.message == 'done'){
              let item = response.data.data[0];
              let param = {
                id: id,
                name: item.name,
                area: item.area,
                size_w: item.size_w,
                size_h: item.size_h,
                size_d: item.size_d,
                mark: item.mark,
                drawing: item.drawing,
                //subgroup: item.subgroup,
                photo_path: item.photo_path,
                click: this.updateitem
              }
            }
          }
      );
    },
    window_show_item(item){
      //this.frmitemparam.subgroup = this.subgroups;
      this.window_params = {
        width: 743,
        height: 502,
        show: true,
        content: this.frmCatItm,
        frmparam: item
      }
      //this.item = item;
    },
  }
}
</script>

<style scoped>

.maintab{
  width: 100%;
}

.maintab tr td:first-child{
  width: 15%;
}

.maintab tr td{
  vertical-align: top;
}

.itemtab{
  width: 98%;
  margin: 1%;
/*  border-collapse: collapse; */
  border-collapse: separate;
  border-spacing: 0 4px; /* Горизонтальное и вертикальное расстояние между ячейками */
}

.itemtab tr th{
  height: 48px;
  background: #bbb;
  color: #111;
}

.itemtab tr th:first-child{
  border-radius: 6px 0 0 0;
}
.itemtab tr th:last-child{
  border-radius: 0 6px 0 0;
}

.itemtab tr{
    transition-property: background-color, box-shadow, transform;
    transition:  0.5s ease;
}

.itemtab tr:hover{
    box-shadow: 0px 0px 5px rgba(10,30,200,0.95);
    /*transform: scale(1.02, 1.02);*/
}

.itemtab tr td{
  height: 32px;
  background: #dddddd;
  padding-top: 12px;
}

.itemtab tr td:first-child{
  border-radius: 6px 0 0 6px;
}
.itemtab tr td:last-child{
  border-radius: 0 6px 6px 0;
}

/* Стили для внешнего списка */
ul.accordion {
  list-style: none;
  padding: 0;
  margin: 0;
}

ul.accordion > li {
  border-bottom: 1px solid #ddd; /* Разделительные линии между элементами */
}

/* Стили для заголовков элементов аккордеона */
ul.accordion > li {
  display: block;
  padding: 8px 2px;
  color: #333;
  text-decoration: none;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

ul.accordion > li:hover {
  background-color: #ddd;/*#e9e9e9;*/
  cursor: pointer;
  box-shadow: 0px 0px 15px rgba(10,10,10,0.125);
}

/* Стили для внутреннего списка */
ul.accordion li ul {
  list-style: none;
  padding: 0;
  /*display: none;  Скрыть внутренний список по умолчанию */
  margin: 0; /* Удалить внешние отступы */
}

ul.accordion li ul li {
  padding: 8px 15px;
  background-color: #fff;
  border-top: 1px solid #eee; /* Разделительные линии между внутренними элементами */
  transition-property: background-color, box-shadow, transform;
  transition:  0.3s ease;
}

ul.accordion li ul li:hover {
  background-color: #f0f0f0;
  cursor: pointer;
  transform: scale(1.02, 1.02);
  box-shadow: 0px 0px 15px rgba(10,10,10,0.5);
}

.btnImg{
  margin: 0 8px;
  transition-property: transform;
  transition:  0.3s ease;
}

.btnImg:hover{
  transform: scale(1.5);
}

.grids{
  width: 100%;
  height: 564px;
  max-height: 564px;
  margin: auto;
  overflow-y: auto;
  overflow-x: hidden;
}

#app{
  overflow-y: hidden;
}

.CatalogLiteContent
{
    background: #eee;
}

.product {
  /*opacity: 0;  Изначально скрыто
  margin-top: 300px;
  transition: opacity, margin-top 0.75s;*/ /* Плавное изменение прозрачности */
  /* Другие стили для товара */
}

.fst{
  margin-top: 64px !important;
  opacity: 0.5; /* Изначально скрыто */
}

@media screen and (max-width: 1050px) {
  .CatalogLiteContent {
    min-height: 559px;
  }
  .menutab {
    position: relative;
  }

  .maintab {
    width: 98%;
    margin: 0 auto;
  }
  .maintab tr td:first-child {
    width: 100%;
    z-index: 1;
  }
  ul.accordion li ul {
    display: flex;
    overflow-y: initial;
    overflow-x: auto;
  }
  .grids {
    display: flex;
    width: 100%;
    overflow-y: initial;
    overflow-x: auto;
    height: 433px;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: flex-start;
    gap: 10px;
  }
  .btnImg:hover{
    transform: none;
  }
  .fst{
    margin-top: 0 !important;
    opacity: 1 !important;
  }
  .cardstab {
    position: absolute;
    top: 0;
    max-width: 100%;
  }
  .product {
    transition: initial;
  }
}
</style>
