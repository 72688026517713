<template>
  <div class="d-fl fl-clm">
    <div class="title">Галерея</div>
    <div class="gallery">
      <gallery-item v-for="img in images" :key="img" :param="img" @click="getName(img)"/>
    </div>
    <div v-if="params.dnd" class="drop">
      <drag-n-drop />
    </div>
  </div>
</template>

<script>
import GalleryItem from "@/components/catalogs/galleryItem.vue";
import API from "@/API";
import DragNDrop from "@/components/catalogs/DragNDrop.vue";

export default {
  name: "gallery",
  components: {DragNDrop, GalleryItem},
  props:{
    params:{
      type: Object,
      required: true,
      default:{
        dnd: true,
        returning: false,
        name: '',
        action: ()=>{},
      }
    }
  },
  mounted() {
    this.getData();
  },
  data(){
    return{
      images:[]
    }
  },
  methods:{
    getData(){
      let uri = 'Gallery/get/';
      API.post(uri, {}).then(
          response => {
            this.images.length = 0;
            response.data.data.items.forEach(g => {
              this.images.push(g);
            })
          }

      );
    },
    getName(item){

      if (this.params.returning){

        this.params.name = item.name;
        console.log(this.params.action)
        if(this.params.action != undefined){

          this.params.action(this.params);
        }
      }
    }
  }
}
</script>

<style scoped>

.gallery
{
  width: 800px;
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 8px;
}

.drop{
  width:800px;
  height:200px;
}

.title{
  background: #bbb;
  color: #111111;
  font-size: 16px;
  height: 48px;
  line-height: 48px;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
}

</style>