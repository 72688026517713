<template>
  <!--<div class="map-container">-->
    <div id="map"></div>
  <!--</div>-->
</template>

<script>

export default {
  name:"mapY",
  data(){
    return{
      map:{},
      placemarks: [
        {
          coords: [54.8, 39.8],
          properties: {}, // define properties here
          options: {}, // define options here
          clusterName: "1",
          callbacks: { click: function() {} }
        }
      ],
      settings:{
        apiKey: 'ecb116f9-4a07-4dd6-a46a-9620f91070bc',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
      },
      center: [52.628711, 39.553370],
      zoom: 17
    }
  },
  /*created() {
    let sYandexMap = document.createElement('script');
    sYandexMap.setAttribute('src', 'https://api-maps.yandex.ru/2.1/?apikey=ecb116f9-4a07-4dd6-a46a-9620f91070bc&lang=ru_RU');
    document.head.appendChild(sYandexMap);
    sYandexMap.addEventListener("load", this.initializeYandexMap);
  },*/
  beforeMount() {
    if (!document.querySelector('script[src*="api-maps.yandex.ru"]')) {
      let sYandexMap = document.createElement('script');
      sYandexMap.setAttribute('src', 'https://api-maps.yandex.ru/2.1/?apikey=ecb116f9-4a07-4dd6-a46a-9620f91070bc&lang=ru_RU');
      document.head.appendChild(sYandexMap);
      sYandexMap.addEventListener("load", this.initializeYandexMap);
    }
  },
  mounted() {

  },
  methods:{
    initializeYandexMap() {
      ymaps.ready(() => {
        this.map = new ymaps.Map("map", {
          center: this.center,
          zoom: this.zoom,
          controls: ['fullscreenControl'],
          searchControlProvider: 'yandex#search'
        });
        this.setMarkers();
      });
    },
    setMarkers() {
      let points = [{coords:[52.629487, 39.553145], title: 'R', content: ''}];
      if(points.length > 0) {
        points.forEach(m => {
          let myGeoObject = new ymaps.GeoObject({
            // Описание геометрии.
            geometry: {
              type: "Point",
              coordinates: m.coords
            },
            // Свойства.
            properties: {
              iconContent: m.title,
              hintContent: m.content
            }
          }, {
           //preset: 'islands',
            draggable: true
          })

          this.map.geoObjects.add(myGeoObject);
        });
      }
    }
  },
  props: {
    markers: {
      type: Array,
      default: []
    },
  },
};
</script>

<style scoped>
.map-container {
  height: 400px;
  margin-bottom: 20px;
}

#map {
  /*position: absolute;
  left: 20vw;*/
  width: 100%;
  /*min-width: 1024px;*/
  height: 400px;
  padding: 0px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.125);
  /*margin: auto;*/
}
</style>
