<template>
  <div class="d-fl main_menu">
    <div class="main_menu_item d-fl" @click="scrollTo('target-catalog')">
      <div class="mm_item d-fl">
        <div class="main_menu_item_title">
          Каталог
        </div>
      </div>
    </div>
    <div class="main_menu_item d-fl" @click="scrollTo('target-info')">
      <div class="mm_item d-fl">
        <div class="main_menu_item_title">
          Полезная информация
        </div>
      </div>
    </div>
    <div class="main_menu_item d-fl" @click="scrollTo('target-about')">
      <div class="mm_item d-fl">
        <div class="main_menu_item_title">
          О Компании
        </div>
      </div>
    </div>
    <div class="main_menu_item d-fl" @click="scrollTo('target-contact')">
      <div class="mm_item d-fl">
        <div class="main_menu_item_title">
          Контакты
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "mainmenu",
  methods: {
    scrollTo(id) {
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({behavior: 'smooth'});
      }
    }
  }
}
</script>

<style scoped>

.main_menu
{
  width: 60%;
  min-width: 1024px;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  margin: auto;
}

.main_menu_item
{
  width: 25%;/**/
  height: 100%;
  color: #444;
  border-top: 2px solid #eeeeee;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  transition-property: border-top;
  transition-duration: .8s;
  /*box-shadow: 0 0 2px rgba(76,87,159,0.9);*/
}

.main_menu_item:hover {
  color: #3366ff;/*#5648ed;*/
  border-top: 2px solid #3366ff;
  cursor: pointer;
  /*transition: transform 1s ease;
  box-shadow: 0 0 2px rgba(76,87,159,0.9);*/
}

.main_menu_item_img {
  /*width: 30%;*/
}
.main_menu_item_img img{
  margin-top: 6px;
  margin-left: auto;
  width: 28px;
  height: 28px;
}

.main_menu_item_title {
  text-align: left;
}

.mm_item
{
  /*display: contents;*/
  margin: auto;
}
</style>