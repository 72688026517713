<template>
  <div class="container">
    <div class="d-fl fl-clm m-c">
      <div class="txt-32 t-bold"></div>
      <div class="spec_item d-fl m-c">
        <div class="d-fl fl-clm pd-lr">
          <img src="@/assets/money.svg" class="m-c imgsi">
          <div class="w-30 txt-18 m-c d-c">Собственное производство</div>
        </div>
        <div class="d-fl fl-clm pd-lr">
          <img src="@/assets/make.svg" class="m-c imgsi">
          <div class="w-30 txt-18 m-c d-c">Изготавливаем детали по вашим <br> чертежам и образцам</div>
        </div>
        <div class="d-fl fl-clm pd-lr">
          <img src="@/assets/rocket.svg" class="m-c imgsi">
          <div class="w-30 txt-18 m-c d-c">14 лет опыта с крупнейшими предприятиями<br> России и ближнего зарубежья</div>
        </div>
      </div>

      <!--<div class="txt-24 t-bold pd-t">Наши партнёры</div>

      <div class="partner-content d-fl pd-t m-c">
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-1_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-2_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-3_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-4_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-5_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-7_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-10_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-11_120x70_1d4.jpg" class="imgpart"></div>
        <div class="partner"><img src="http://www.tpgrd.ru/assets/cache_image/assets/template/images/brands/partner-12_120x70_1d4.jpg" class="imgpart"></div>
      </div>-->

    </div>

  </div>
</template>

<script setup>
import { ref } from 'vue';

const expandedItemId = ref(null);

const toggleItem = (id) => {
  if (expandedItemId.value === id) {
    expandedItemId.value = null;
  } else {
    expandedItemId.value = id;
  }
};
</script>

<style scoped>
.container {
  /* Стили контейнера */
  width: 72%;
  margin: auto;
  display: block;
}

.imgsi{
  opacity: 1 !important;
  width: 64px;
  padding: 32px;
}

.imgpart{
  width: 128px;
  padding: 8px;
}
.partner-content
{
  width: 100%;
  overflow-x: auto;
}
@media screen and (max-width: 900px) {
  .spec_item {
    flex-direction: column !important;
  }
}
</style>
