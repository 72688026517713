<template>
<div class="d-fl fl-clm img_my">
  <div class="img_img">
    <img :src="getImage(param.name)" alt="Изображение" class="img">
  </div>
  <div class="img_name">{{ param.name }}</div>
</div>
</template>

<script>

export default {
  name: "galleryItem",
  props:{
    param:{
      type:Object,
      required: true,
      default:{
        name:'1.png'
      }
    }
  },
  data(){
    return{
      uri: 'https://riche.bot-cloud.ru/images/1/' //'http://riche.my/images/1/'
    }
  },
  methods:{
    getImage(name){
      return this.uri + name; //"background:url("+path+");"
    }
  }
}
</script>

<style scoped>



</style>
