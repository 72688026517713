<template>
  <div>
    <div class="mycart " :class="{hideCart :!showCart}">
      <div class="showhide" @click="showHideCart()">
        <!--<img v-if="showCart" :src="require('@/assets/odmin/show.svg')">-->
        <div v-if="showCart" class="menubtn btnclose" style="">X</div>
        <img v-if="!showCart"  :src="require('@/assets/odmin/cart.svg')">
      </div>
      <div v-if="!showCart" class="counter">{{ getCounts() }}</div>
      <div v-if="showCart" style="font-weight:bold;text-align: center;width: 100%;margin-top: 16px;">ЗАКАЗ</div>
      <div class="inCart">
        <div v-if="showCart" v-for="item in cartItems" :key="item.id" class="d-fl" style="padding: 4px 16px;margin-bottom: 8px;">
          <div class="iname">{{ item.name }}</div>
          <div class="xmi"> x </div>
          <input class="inputs counts" type="number" :id="'item_'+item.id" :value="item.count" @keyup="setCount(item)">
          шт.
          <div class="btn_class kill" @click="killItem(item)"> x </div>
        </div>
      </div>
      <input v-if="showCart" type="button" value="ЗАКАЗАТЬ" class="btn_class m-t m-lr btnS" @click="window_user_info()">
    </div>
    <window :params="window_params" :item="item"/>
  </div>
</template>

<script>
import Cart from "@/cart";
import Window from "@/components/Window.vue";
import frmUser from "@/components/catalogs/frmUser.vue";

export default {
  components: {Window},
  setup() {
    // Поскольку Cart.state.items уже реактивный, он будет обновляться автоматически
    const cartItems = Cart.state.items;

    // Добавление элемента в корзину
    function addItemToCart(item) {
      Cart.plus(item);
    }

    // Возвращаем реактивные свойства и методы для использования в шаблоне
    return {
      cartItems,
      addItemToCart
    };
  },
  data(){
    return{
      showCart: false,
      window_params:{width:320, height:150, show:false},
      item:{},
      frmUsr: frmUser,
      frmparam: {
        name: '',
        placeholder: 'Заполните поля.',
        click: this.win_close,
      },
    }
  },
  methods:{
    window_user_info(){
      if(Cart.get().length > 0) {
        this.frmparam.show = this.window_params.show
        this.window_params = {
          width: 320,
          height: 150,
          show: true,
          content: this.frmUsr,
          frmparam: this.frmparam
        }
      }
    },
    showHideCart(){
      this.showCart = !this.showCart;
    },
    win_close(){
      this.window_params = false;
    },
    setCount(item){
      let count = document.querySelector('#item_'+item.id).value;
      Cart.setcount(item, count)
    },
    getCounts(){
      return Cart.state.items.length;
    },
    killItem(item){
      item.count = 0;
      //Cart.minus(item);
      Cart.remove(item);
    }
  }
};
</script>

<style scoped>
.mycart{
  position: fixed;
  bottom: 32px;
  right: 32px;
  width: 378px;
  height: 512px;
  background: #dddddd;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  z-index:9999;
  box-shadow: 0px 0px 15px rgba(10,10,10,0.25);
}

.hideCart{
  width: 48px !important;
  height: 48px !important;
}

.showhide{
  width: 32px;
  height: 32px;
  position: absolute;
  right: 8px;
  top: 12px;
}

.btnclose{
  width: 32px;
  height: 32px;
  position: absolute;
  left: -304px;
  top: 0px;
  font-size: 20px;
  color: #224499;
  font-weight: bold;
}

.counts{
  margin: initial;
  margin-left: auto;
  width: 48px;
  height: 14px;
  line-height: 14px;
  border-radius: 6px;
  font-size:12px;
  margin-right: 6px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.iname{
  text-align: left;
  margin-right: auto;
  width: 212px;
}

.xmi{
  margin-left: auto;
}

.btnS{
  margin-bottom: 8px !important;
  width: 128px;
}

.counter{
  width: 16px;
  height: 16px;
  border-radius: 50%;
  line-height: 16px;
  font-weight: bold;
  text-align: center;
  background: #3366ff;
  color: #dddddd;
  position: absolute;
  top: -8px;
  left: -8px;
}

.inCart{
  height:80%;
  overflow-y: auto;
  margin-top: 16px;
}

.kill{
  width: 24px;
  height: 24px;
  line-height: 24px;
  border-radius: 50%;
  margin-left: 8px;
}
@media screen and (max-width: 600px) {
  .mycart {
    bottom: 32px;
    right: 2px;
    width: 352px;
  }
}
</style>
