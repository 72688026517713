<template>
  <div>
    <!--<div class="content">Прокрутите вниз</div>-->
    <section id="target-start"></section>
    <stickybar/>
    <div class="setk">
      <div class="title_page">
        Производим и поставляем детали для горнодобывающей спецтехники на выгодных условиях
      </div>
    </div>
    <div class="parallax" :style="{backgroundPositionY: offset + 'px'}">
      <div class="header">
        <table style="min-width:1024px;width:60%;height:100%;margin: auto;">
          <tr>
            <td></td>
            <td style="width:30%;"><img src="@/assets/logo.png" style="width: 224px;margin-top: 8px;" alt=""></td>
            <td style="width:40%;"><input id="search" style="margin:auto;" placeholder="ПОИСК ПО КАТАЛОГУ"></td>
            <td class="mytitle" style="width:20%;">
              <div class="d-fl fl-clm" style="width:100%;">
                <a href="tel:+74742797369" style="margin-left:auto;">+7 (4742) 79-73-69 </a>
                <a href="tel:+74742351141" style="margin-left:auto;">+7 (4742) 35-11-41 </a>
              </div>
            </td>
            <td></td>
          </tr>
        </table>
      </div>

    </div>

    <section id="target-info">
      <spec-info/>
      <animate-belaz />
    </section>

    <section id="target-catalog" style="margin-top: 128px;">
        <cataloglite />
   </section>

    <section id="target-about">
      <about-info/>
    </section>

    <section id="target-contact">
      <contacts/>
    </section>

    <div class="content"></div>

    <div class="footer">
    <div class="parallax_footer" :style="{backgroundPositionY: (offset + 256) + 'px'}">
        <div class="setk black" style="height: 370px;">
            <div class="w-100 d-fl">

            </div>
        </div>
    </div>
    </div>

    <div class="upper" :class="{uppershow: offset <= -95}" @click="scrollTo('target-start')">
      <img src="@/assets/up.svg" class="m-c imgsi" style="margin: -40px;">
    </div>

    <!--<div class="debugger">
      {{ offset }}
    </div>-->

  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Stickybar from "@/components/stickybar.vue";
import CatalogTwo from "@/components/CatalogTwo.vue";
import SpecInfo from "@/components/SpecInfo.vue";
import Contacts from "@/components/Contacts.vue";
import Catalog from "@/components/catalog.vue";
import AboutInfo from "@/components/AboutInfo.vue";
import CatalogNew from "@/components/CatalogNew.vue";
import Cataloglite from "@/components/catalogs/cataloglite.vue";
import VisibleContent from "@/components/tests/visibleContent.vue";
import AnimateBelaz from "@/components/tests/AnimateBelaz.vue";

const offset = ref(0);
const handleScroll = () => {
  offset.value = - window.scrollY / 6; // Подстроить коэффициент для изменения скорости параллакса
};

onMounted(() => {
  window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

</script>

<style scoped>
.parallax {
  /* Путь к вашему фоновому изображению bel32.jpg*/
  background-image: url('@/assets/bela2.jpg');

  /* Высота секции с параллакс-эффектом */
  height: 570px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Добавим немного отступа для визуального эффекта
  padding: 100px 0;*/
  color: white;
  text-align: center;
  font-size: 36px;
  font-family: Arial, sans-serif;
  margin-top: -174px;
}
.parallax_footer {
  background-image: url('@/assets/kowshi.jpg');

  /* Высота секции с параллакс-эффектом */
  height: 370px;

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  /* Добавим немного отступа для визуального эффекта
  padding: 100px 0;*/
  color: white;
  text-align: center;
  font-size: 36px;
  font-family: Arial, sans-serif;
  margin-top: -174px;
}

.content {
  height: 128px;
  background: rgba(200,200,200,0.25);
  text-align: center;
  padding-top: 50px;
  font-family: Arial, sans-serif;
}
.header{
  height: 128px;
  background: rgba(221, 221, 221, 0.72);
  /*filter: blur(8px);*/
}
table {
  font-size: 14px;
  color: #111111;
}
.mytitle {
  text-align: left;
  padding-left: 24px;
  line-height: 20px;
}
a{
  font-size: 14px;
  font-weight: bold;
  color: #111;
  text-decoration: unset;
  cursor: pointer;
}
a:hover{
  color: #555;
}
input {
  height: 24px;
  background: rgba(30,30,30,.75);
  border: 1px solid #ccc;
  border-radius: 14px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  outline: none;
  color: #eee;/*#5648ed;*/
  position: relative;
  width: 90%;
  text-align: center;
}
.setk{
  background: url("@/assets/setka.png");
  width: 100%;
  height: 396px;
  position: absolute;
}
.black{
  background-color: rgba(5,5,5,0.75);
}
.title_page {
  width: 100%;
  height: 74px;
  background: rgba(10,10,10,0.75);
  text-align: center;
  font-size: 24px;
  line-height: 74px;
  color: #eeeeee;
  position: absolute;
  bottom: 0px;
}
section{
  padding-top: 64px;
  padding-bottom: 64px;
  border-top:1px solid #bbb;
}
#target-start{
  padding: 0px;
  position: absolute;
  top: 0px;
}
.upper{
  position: fixed;
  z-index: 999;
  /*background: #3366ff;*/
  color: #eee;
  line-height: 48px;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  width: 48px;
  height: 48px;
  right: 32px;
  top: 64px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0px 7px #3366ff;
  opacity: 0;
  transition-property: opacity;
  transition-duration: .5s;
}
.uppershow {
  opacity: 1;
}
.footer {
  margin-top: auto;
}


</style>
