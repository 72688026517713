<template>
<table class="calitem">
  <tr class="calitem_tr">
    <td class="w-40 h100 calitem_td">
      <!--<img :src="getImage()" class="calitem_img" style="width: 153px;max-height: 153px;">-->
      <div class="image m-c calitem_img" :style="getBackImage()"></div>
    </td>
    <td class="border h100 calitem_td w-100">
      <div class="h100 ml-a corrinfo">
        <div class="d-fl fl-clm h100">

          <div class="name">{{ params.name }}</div>

          <div class="title_name">Область применения</div>
          <div class="infofo">{{ setLength(25,params.area) }}</div>
          <div class="title_name">Размер</div>
          <div class="infofo">{{ params.size_w + 'x' + params.size_h + 'x' + params.size_d }}</div>

          <div class="buttons">
            <img v-if="params.access" class="btnImg" :src="require('@/assets/odmin/edit.svg')" @click="getItem()">
            <img class="btnImg" :src="require('@/assets/odmin/show.svg')" @click="window_show_item()">
            <img class="btnImg" :class="{borderbtn: showaddbtn}" :src="require('@/assets/odmin/cart.svg')" @click="add()">
            <!--<img class="btnImg" :src="require('@/assets/odmin/trash.svg')" >-->
          </div>
        </div>
      </div>
    </td>
  </tr>
</table>
</template>

<script>
import cart from "@/cart";

export default {
  name: "catalogLiteItem",
  props: {
    params:{
      type: Object,
      required: true,
      default:
          {
            name: 'Name',
            area: 'Area1, Area2, Area3',
            size_w: '10',
            size_h: '20',
            size_d: '12.5',
            mark: 'Mark_123',
            drawing: '1.drawing.21',
            subgroup: '1',
            photo_path: 'ДК-724.png'
          }
    },
    function:{
      type: Object,
      required: true,
    }
  },
  data(){
    return{
      img_url: 'https://riche.bot-cloud.ru/images/1/',//http://riche.my/images/1/',
      showaddbtn: this.getInCart(),
    }
  },
  methods:{
    getImage(){
      if(this.params.photo_path != undefined
          && this.params.photo_path != null
          && this.params.photo_path != ''
          && this.params.photo_path != 0
          && this.params.photo_path != '0') {
        return this.img_url + this.params.photo_path;
      }else{
        return require('@/assets/no-photo.png');
      }
    },
    getBackImage(){
      return 'background-image:url("'+this.getImage()+'");';
    },
    setLength(maxLength, text){
      if (text.length > maxLength) {
        text = text.substr(0, maxLength) + '...';
      }

      return text;
    },
    getItem(){
      this.function.getItem(this.params.id);
    },
    window_show_item(){
      this.function.window_show_item(this.params);
    },
    add(){
      let item = JSON.parse(JSON.stringify(this.params));
      item.count = 1;
      cart.plus(item);
      this.showaddbtn = true;
    },
    getInCart(){
      let incart = cart.get();
      return (incart.find(i => i.id === this.params.id) !== undefined)
    },
  }
}
</script>

<style scoped>

.image{
  width: 153px;
  height: 153px;
  background-repeat: no-repeat ;
  background-position: center center;
  background-size: 60%;
}

.calitem{
  /**/
  width: 32%;
  height: 228px;
  float: left;
  margin-top: 16px;
  margin-left: 1%;
  background: #fff;
  overflow: hidden;
  overflow-wrap: break-word;
  padding: 0;
  text-decoration: none;
  transition-duration: .28s;
  transition-property: box-shadow,opacity;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  z-index: 0;
  border-color: rgba(47,43,61,0.16);
  border-style: solid;
  border-width: 0;
  border-radius: 6px;
  border-collapse: collapse;
  box-shadow: 0 2px 6px rgba(47,43,65,.14),0 0 transparent,0 0 transparent;
}

.calitem tr td:first-child{
  border-right: 2px solid #eee;
}
.calitem tr td{
  vertical-align: top;
  padding: 16px;
}

.h100{
  height: 100%;
}

.name{
  margin-left: auto;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 18px;
}

.title_name{
  margin-left: auto;
  font-weight: bold;
  margin-top: 8px;
}

.infofo{
  margin-left: auto;
  text-align: right;
}

.btnImg{
  float: right;
  margin: 0 8px;
  transition-property: transform;
  transition:  0.3s ease;
}

.btnImg:hover{
  transform: scale(1.5);
}

.buttons{
  margin-top: auto;
  height:48px;
  width: 100%;
  padding-top: 24px;
}

.borderbtn{
  border: 2px solid red;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(10,10,10,0.5);
}

.calitem_td {
  display: flex;
  width: 158px;
  height: 196px;
}

.calitem_tr {
  display: flex;
}

@media screen and (max-width: 1050px) {
  .calitem_tr {
    display: flex;
    flex-direction: column;
  }
  .calitem_img {
    width: 50% !important;
    max-height: 118px !important;
  }
  .name {
    font-size: 14px;
    margin-right: auto;
  }
  .buttons {
    padding: 0;
    height: auto;
  }
  .title_name,
  .infofo {
    margin: 0 auto;
  }
  .calitem {
    height: initial;
    margin-left: 0;
    flex-basis: calc(33.333% - 7px);
    box-sizing: border-box;
  }
  .calitem_td {
    padding: 10px !important;
    width: 100%;
  }
  .btnImg {
    float: initial;
  }
  .corrinfo{
    margin-right: auto;
  }
}
@media screen and (max-width: 600px) {
  .name {
    font-size: 12px;
    max-width: 156px;
  }
  .calitem {
    flex-basis: calc(50% - 5px);
  }
}
</style>
