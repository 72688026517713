<template>
  <div class="container">
    <div class="d-fl fl-clm m-c">
      <div class="txt-32 t-bold">КОНТАКТНАЯ ИНФОРМАЦИЯ</div>
      <div class="contact_items d-fl m-c" style="width: 100%;">
        <div class="contact_item d-fl fl-clm pd-lr w-30 h-256">
          <img src="@/assets/home.svg" class="m-c imgsi">
          <div class="contact_item_title txt-24 t-bold">АДРЕС</div>
          <div class="w-30 txt-18 m-c d-c">398037, Россия, г. Липецк, Боевой проезд, 23Б <br> &emsp;</div>
          <div class="txt-24 t-bold m-t pd-t"></div>
        </div>
        <div class="contact_item d-fl fl-clm pd-lr w-30 h-256">
          <img src="@/assets/phone.svg" class="m-c imgsi">
          <div class="contact_item_title txt-24 t-bold">ТЕЛЕФОНЫ</div>
          <div class="w-30 txt-18 m-c d-c">
            <div class="d-fl fl-clm" style="width:100%;">
              <a class="txt-18" href="tel:84742797369">+7 (4742) 79-73-69 </a>
              <!--<a class="txt-18">+7 (4742) 35-11-41 </a>-->
            </div>
            <div class="contact_item_button txt-24 t-bold m-t pd-t">
              <input type="button" class="btn_class" value="ПОЗВОНИТЬ" @click="telto()">
            </div>
          </div>
        </div>
        <div class="contact_item d-fl fl-clm pd-lr w-30 h-256">
          <div class="h-64">
            <img src="@/assets/mail.svg" class="m-c imgsi">
          </div>
          <div class="contact_item_title txt-24 t-bold" style="padding-top: 64px;">ПОЧТА</div>
          <div class="d-fl fl-clm" style="width:100%;">
            <a class="txt-18" href="mailto:tpg.rd@mail.ru">tpg.rd@mail.ru </a>

            <!--<a class="txt-18">.</a>-->
          </div>
          <div class="contact_item_button txt-14 t-bold m-t pd-t d-fl fl-clm">
            <!--<input type="button" class="btn_class" value="НАПИСАТЬ" @click="mailto()">-->
            <div class="d-fl m-lr"><a class="txt-14" href="mailto:tpgrd.log@gmail.com">tpgrd.log@gmail.com</a> - логистика</div>
            <div class="d-fl m-lr"><a class="txt-14" href="mailto:tpgrd.buh@gmail.com">tpgrd.buh@gmail.com</a> - бухгалтерия</div>
          </div>
        </div>
      </div>

    </div>

    <div class="map-container">
      <div class="txt-24 t-bold pd-t">СХЕМА ПРОЕЗДА</div>
      <map-y/><!---->
    </div>

  </div>
</template>

<script setup>
import { ref } from 'vue';
import MapY from "@/components/mapY.vue";

const expandedItemId = ref(null);

const toggleItem = (id) => {
  if (expandedItemId.value === id) {
    expandedItemId.value = null;
  } else {
    expandedItemId.value = id;
  }
};

</script>

<script>
export default{
  mounted() {

  },
  methods:{
    mailto(){
      window.location.href = "mailto:tpg.rd@mail.ru"
    },
    telto(){
      window.location.href = "tel:84742797369"
    }
  }
}
</script>
<style>
a{
  text-decoration: none;
  color: #2c3e50;
}

a:hover{
  color: #3366ff;
}

.container {
  /* Стили контейнера */
  width: 72%;
  margin: auto;
}

.imgsi{
  width: 64px;
  padding: 32px;
}

.imgpart{
  width: 128px;
  padding: 8px;
}
.map-container
{
  margin-top: 32px;
  width: 100%;
  height: 400px;

}

.btn_class
{
  background-color: #3366ff;
  color: #eeeeee;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  outline: none;
  border: none;
  border-radius: 6px;
  width: 192px;
  height: 32px;
}
@media screen and (max-width: 900px) {
  .contact_items {
    flex-direction: column !important;
  }
  .contact_item {
    width: 100% !important;
    align-items: center !important;
    height: initial !important;
    padding: 0 !important;
  }
  .btn_class {
    /*margin-top: 10px !important;*/
  }
  .contact_item_button {
    padding: 0 !important;
    margin: 0 !important;
  }
  .imgsi {
    width: 38px;
    padding: 0;
    height: 64px;
  }
  .contact_item_title {
    padding: 0 !important;
  }
  .contact_item {
    margin-top: 25px;
  }
}
</style>