<template>
  <div class="main_clite" @click="makecartvis($event)">
    <!--<div class="content">Прокрутите вниз</div>-->

    <!--<div class="header">
      <table class="header_table">
        <tr>
          <td class="header_td" style="width:240px;">
            <img src="@/assets/logo.png" class="header_img" alt="" @click="mainpage()">
          </td>
          <td class="header_td mytitle">

          </td>
          <td class="header_td">
            <div class="title"> </div>
          </td>
        </tr>
      </table>
    </div>-->
    <cataloglite :params="{access:false}" />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import Stickybar from "@/components/salt/stickybar.vue";
import CatalogTwo from "@/components/CatalogTwo.vue";
import SpecInfo from "@/components/SpecInfo.vue";
import Contacts from "@/components/Contacts.vue";
import Catalog from "@/components/catalog.vue";
import AboutInfo from "@/components/AboutInfo.vue";
import CatalogNew from "@/components/CatalogNew.vue";
import Cataloglite from "@/components/catalogs/cataloglite.vue";
import VisibleContent from "@/components/tests/visibleContent.vue";
import AnimateBelaz from "@/components/tests/AnimateBelaz.vue";
import AnimateElectro from "@/components/tests/AnimateElectro.vue";
import CatalogLiteItem from "@/components/catalogs/catalogLiteItem.vue";
import CatalogliteRND from "@/components/catalogs/catalogliteRND.vue";

const offset = ref(0);

const handleScroll = () => {
  offset.value = - window.scrollY / 6; // Подстроить коэффициент для изменения скорости параллакса
  const mmenu = document.querySelector('.header');
  if(window.scrollY > 0){ mmenu.classList.add('header_shadow') ;}else{ mmenu.classList.remove('header_shadow') ;}
  let sections = document.querySelectorAll('section');
  sections.forEach(sec=>{
    const secPosition = sec.getBoundingClientRect().top + 32;
    const windowHeight = window.innerHeight;

    if (secPosition <= windowHeight) {
      sec.classList.add('section_animate');
    } else {
      sec.classList.remove('section_animate');
    }
  })

};

onMounted(() => {
  //window.addEventListener('scroll', handleScroll);
});

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll);
});

const scrollTo = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

</script>

<script>

export default{
  name: "Catalogs",
    data(){
        return{
            mouseX:0,
            mouseY:0,
        }
    },
    mounted() {
        const elem = document.querySelector('#app'); // выбираем элемент, на котором будем отслеживать движение мыши
        let s = this;
        elem.addEventListener('mousemove', function (event) {
            // добавляем обработчик события "mousemove"
            const x = event.clientX; // получаем координату X мыши
            const y = event.clientY; // получаем координату Y мыши
            s.mouseX = x;
            s.mouseY = y;
            //console.log(`Координаты мыши: x=${x}, y=${y}`); // выводим координаты мыши в консоль
        });
/*
        elem.addEventListener('click', function (event) {
            //alert(event);
            console.log(event);
            this.makecartvis();
            //console.log(`Координаты мыши: x=${x}, y=${y}`); // выводим координаты мыши в консоль
        });

 */
    },
  methods:{
    mainpage(){
      window.location.href = './';
    },
    makecartvis(event){

        if(event.target.id != 'brushes'){ return 0; }

        let cart = document.createElement('div')
        cart.style.width = '32px';
        cart.style.height = '32px';
        cart.style.background = 'url('+require('@/assets/odmin/cart.svg')+') center center no-repeat';
        cart.style.backgroundSize = '24px';
        cart.style.position = 'fixed';
        cart.style.top = (this.mouseY - 16) + "px";
        cart.style.left = (this.mouseX - 16) + "px";
        cart.style.transitionProperty = 'opacity transform';
        cart.style.transitionDuration = '0.9s';
        cart.style.opacity = '1';

        let mapp = document.querySelector('#app');
        mapp.appendChild(cart);

        setTimeout(()=>{cart.style.opacity = '0'; cart.style.transform = 'scale(2, 2) translate(0, -100%)'}, 100);
        setTimeout(()=>{cart.parentNode.removeChild(cart);}, 1000);

    }
  }
}

</script>

<style scoped>

.main_clite{
  background: #f0f0f0;
}

.header{
  height: 64px;
  background: none;
  position: fixed;
  z-index: 99;
  transition-property: box-shadow, background-color;
  transition-duration: .3s;
  top: 0;
  /*filter: blur(8px);*/
}
.header_table {
  min-width:1024px;
  width:100vw;
  height:64px;
  margin: auto;
  background: #f0f0f0;
  border-collapse: collapse;
}
.header_td:nth-child(1) {
  background: #fff;
  width: 240px;
}
.header_img {
  width: 140px;
  margin-top: 8px;
}
.header_shadow{
  box-shadow: 0 0 15px rgba(0,0,0,.25);
  background-color: #fff;
}

table {
  font-size: 14px;
  color: #111111;
}
.mytitle {
  text-align: left;
  padding-left: 24px;
  line-height: 20px;
  width:20%;
}
a{
  font-size: 14px;
  font-weight: bold;
  color: #111;
  text-decoration: unset;
  cursor: pointer;
}
a:hover{
  color: #555;
}
input {
  height: 24px;
  background: rgba(30,30,30,.75);
  border: 1px solid #ccc;
  border-radius: 14px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 16px;
  outline: none;
  color: #eee;/*#5648ed;*/
  position: relative;
  width: 90%;
  text-align: center;
}
section{
  min-height: 320px;
  padding-top: 64px;
  padding-bottom: 64px;
  transition-property: margin-top, opacity;
  transition-duration: 1.5s;
  height: calc(100vh - 100px);
}
.section-electro {
  /*margin-top: 128px;*/
}

#target-start{
  padding: 0px;
  position: absolute;
  top: 0px;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; /* Поместите видео под текстовое содержимое */
}

.title{
  font-size: 32px;
  height: 48px;
  line-height: 48px;
  font-weight: bold;
  border-radius: 8px 8px 0 0;
  text-align: right;
}

@media screen and (max-width: 1300px) {
  .header {
    width: 100%;
  }
  .mytitle {
    width: 0;
  }
}
@media screen and (max-width: 1024px) {
  .header_table {
    min-width: initial;
  }
  .section_animate {
    padding-top: 130px;
  }
  .section-electro {
    margin-top: 0;
  }
  .video-background video {
    width: initial;
    height: 100vh;
  }
  .upper {
    top: 19px;
  }
}
@media screen and (max-width: 780px) {
  .title_page {
    font-size: 24px;
  }
  .header_td:first-child {
    width: 15px;
  }
  .section_animate {
    padding-top: 30px;
  }
  .section_animate .container {
    width: 92%;
  }
  .sticky-element {
    display: none;
  }
  .upper {
    right: 77px;
  }
}
@media screen and (max-width: 500px) {
  .header {
    height: 75px;
  }
  .title_page {
    line-height: 40px;
    font-size: 16px;
  }
  .header_img {
    width: 160px;
  }
  .header_table {
    height: 66%;
  }
  .upper {
    top: -5px;
  }
}
</style>
