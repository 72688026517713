<template>
  <div id="dropzone" @drop.prevent="handleDrop" @dragover.prevent @dragenter.prevent>
    Перетащите ваши фотографии сюда
    <input v-if="this.images.length > 0" type="button" value="Отправить" @click="uploadImages()"/>
    <div style="overflow-x: auto; overflow-y: hidden;">
      <div style="display: flex;">
      <div v-for="(image, index) in images" :key="index" class="d-fl fl-clm img_my fl-i">
          <div class="img_img">
            <img :src="image" class="img">
          </div>
          <div class="img_progress">{{ ulprogress }} %</div>
      </div>
      </div>
    </div>

  </div>
</template>

<script>
import API from "@/API";
import APIupload from "@/APIupload";

export default {
  name: "DragNDrop",
  data() {
    return {
      images: [],
      ulprogress:0,
    }
  },
  methods: {
    handleDrop(e) {
      const files = e.dataTransfer.files;
      for (let i = 0; i < files.length; i++) {
        if (files[i].type.match('image.*')) {
          const reader = new FileReader();
          reader.onload = (e) => {
            this.images.push(e.target.result);
          };
          reader.readAsDataURL(files[i]);
        }
      }
    },
    async uploadImages() {
      let formData = new FormData();
      for (let i = 0; i < this.images.length; i++) {
        formData.append('images[]', this.images[i]);
      }

      try {
        const response = await APIupload.post('Gallery/upload', formData, {
          /*headers: {
            'Content-Type': 'multipart/form-data'
          },*/
          onUploadProgress: (progressEvent) => {
            // Вычислить процент загрузки
            let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            console.log(percentCompleted); // Здесь вы можете обновить состояние или вызвать метод для обновления UI

            // Например, если у вас есть метод updateProgress, вы можете вызвать его так:
             this.updateProgress(percentCompleted);
          }
        });
        console.log(response.data); // Обработка ответа от сервера
      } catch (error) {
        console.error('Ошибка при загрузке файлов: ', error);
      }
    },

    updateProgress(percent) {
      this.ulprogress = percent;
      // Обновить UI с процентом загрузки
      // Например, обновить полосу прогресса или отображать процент в текстовом виде
    }
  }
}
</script>

<style>

#dropzone {
  padding: 48px;
  text-align: center;
  border-radius: 8px;
  margin: 1%;
  width: 88%;
  border: 2px dashed #222;
}

.fl-i{
  float: initial;
}

</style>
