<template>
  <div class="d-fl fl-clm">
    <div class="inf"> Оставьте нам контакт </div>
    <!--<input class="inputs" type="text" placeholder="Имя" id="name" v-model="userInfo.name"/>
    <input class="inputs" type="text" placeholder="Почта" id="email" v-model="userInfo.email"/>-->
    <input class="inputs" type="text" placeholder="Телефон или эл.почта" id="contact" v-model="userInfo.contact"/>
    <input class="btn_class m-c mt-16" type="button" value="Подтвердить" @click="updateInfo(); clicked()"/>
  </div>
</template>

<script>
import Gallery from "@/components/catalogs/gallery.vue";
import User from "@/user";
import Window from "@/components/Window.vue";
import API from "@/API";
import cart from "@/cart";

export default {
  name: "frmGroup",
  components: {Window, Gallery},
  props:{
    params:{
      type: Object,
      required: true,
      default:{
        click: ()=>{ alert('Set please action in param!') }
      }
    }
  },
  setup() {
    // Поскольку Cart.state.items уже реактивный, он будет обновляться автоматически
    const userInfo = User.state.info;

    function updateInfo() {
      let item = {
        name: document.querySelector('#contact').value,
        email: document.querySelector('#contact').value,
        phone: document.querySelector('#contact').value/**/
      }
      User.update(item);

    }

    // Возвращаем реактивные свойства и методы для использования в шаблоне
    return {
      userInfo,
      updateInfo
    };
  },
  methods:{
    clicked(){
      this.params.click();
      this.sendOrder();
    },
    itemsFromCart(){
      let items = [];
      if(cart.state.items.length > 0){
        cart.state.items.forEach(item => {
          let i = {
            item_id: item.id,
            count: item.count
          }
          items.push(i)
        });
      }
      return items;
    },
    sendOrder(){
      let uri = 'Order/add/';
      let param = {
        phone: this.userInfo.phone,
        items: this.itemsFromCart(),
        client: this.userInfo,
      }
      if(param.items.length < 1){ alert('Корзина пустая!'); return 0;}
      API.post(uri, param).then(
          response => {
            if(response.data.status == 200){
              cart.clear();
            }
          }
      );
    }
  }
}
</script>

<style scoped>

.inputs{
  height: 32px;
  background: #ddd;
  border: 1px solid #ccc;
  border-radius: 6px;
  margin: auto;
  margin-top: 16px;
  margin-bottom: 2px;
  outline: none;
  color: #5648ed;
  position: relative;
  width:90%;
  text-align: center;
}

.inf{
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 8px;
}

</style>
