<template>
  <div class="d-fl pd8 corrects">
    <div class="d-fl fl-clm">
      <div class="image d-fl">
        <!--<img :src="getImage()" style="width: 100%;">-->
        <div class="image m-c calitem_img" :style="getBackImage()"></div>
      </div>

    </div>
    <div class="item_info">
      <div class="d-fl fl-clm info">

        <div class="name">{{ params.name }}</div>

        <div class="title_name">Область применения</div>
        <div class="infofo">{{ params.area }}</div>
        <div class="title_name">Размер</div>
        <div class="infofo">{{ params.size_w + 'x' + params.size_h + 'x' + params.size_d }}</div>
        <div class="title_name">Маркировка</div>
        <div class="infofo">{{ params.mark }}</div>
        <div class="title_name">Чертеж</div>
        <div class="infofo">{{ params.drawing }}</div>

        <div class="buttons">
          <img class="btnImg" :class="{borderbtn: showaddbtn}" :src="require('@/assets/odmin/cart.svg')" @click="add()">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cart from "@/cart";

export default {
  name: "frmCatalogItem",
  props: {
    params:{
      type: Object,
      required: true,
      default:
      {
        name: 'Name',
        area: 'Area1, Area2, Area3',
        size_w: '10',
        size_h: '20',
        size_d: '12.5',
        mark: 'Mark_123',
        drawing: '1.drawing.21',
        subgroup: '1',
        photo_path: 'ДК-724.png'
      }
    }
  },
  mounted() {
    //console.log(this.params)
  },
  data(){
    return{
      img_url: 'https://riche.bot-cloud.ru/images/1/'//'http://riche.my/images/1/'
    }
  },
  methods:{
    getImage(){
      if(this.params.photo_path != undefined
          && this.params.photo_path != null
          && this.params.photo_path != ''
          && this.params.photo_path != 0
          && this.params.photo_path != '0') {
        return this.img_url + this.params.photo_path;
      }else{
        return require('@/assets/no-photo.png');
      }
    },
    getBackImage(){
      return 'background-image:url("'+this.getImage()+'");';
    },
    add(){
      let item = JSON.parse(JSON.stringify(this.params));
      item.count = 1;
      cart.plus(item);
    },
  }
}
</script>

<style scoped>

.corposbtn{
  margin-top: auto;
  margin-left: 20px;
}

.info{
  width: 238px;
  height: 100%;
}

.image{
  width: 482px;
  height: 482px;
  background-repeat: no-repeat ;
  background-position: center center;
  background-size: 60%;
  background-color: #fff;
}

.name{
  margin-left: auto;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 24px;
}

.title_name{
  margin-left: auto;
  font-weight: bold;
  margin-top: 8px;
}

.infofo{
  margin-left: auto;
  text-align: right;
}

.btnImg{
  float: right;
  margin: 0 8px;
  transition-property: transform;
  transition:  0.3s ease;
}

.btnImg:hover{
  transform: scale(1.5);
}

.buttons{
  margin-top: auto;
  height:48px;
  width: 100%;
  padding-top: 24px;
}

.borderbtn{
  border: 2px solid red;
  border-radius: 50%;
  box-shadow: 0px 0px 5px rgba(10,10,10,0.5);
}

@media screen and (max-width: 1050px) {
  .corrects{
    display: flex;
    flex-direction: column;
  }
  .info{
    width: 89vw;
  }
  .image{
    max-width: 92vw;
    max-height: 92vw;
  }
}

</style>
