<template>
    <nav>
      <!--<router-link to="/">Главная</router-link> |
      <router-link to="/odmin">Админ Панель</router-link>-->
    </nav>
  <!--<slider/>-->
    <router-view/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'
import Slider from "@/components/slider.vue";

export default {
  name: 'App',
  components: {
    Slider,
    HelloWorld
  }
}
</script>

<style>

:root{
  --col-lightblue: #398fc6;
  --col-midblue: #367ab8;
  --col-darkblue: #3253a1;
}

body{
  margin: 0px;
  overflow-x: hidden;
}
#app {
  font-family: "PT Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  font-size: 12px;
  margin-top: 0px;
}

.d-fl
{
  display: flex;
}
.d-c
{
  display: contents;
}
.fl-clm
{
  flex-direction: column;
}

.m-c
{
  margin: auto;
}

.m-8
{
  margin: 0 8px;
}

.mt-0{
  margin-top: 0px !important;
}

.mt-8{
  margin-top: 8px !important;
}

.mt-16{
  margin-top: 16px !important;
}

.mt-32{
  margin-top: 32px !important;
}

.txt-32{
  font-size: 32px;
}

.txt-24{
  font-size: 24px;
}

.txt-21{
  font-size: 21px;
}

.txt-18{
  font-size: 18px;
}

.txt-16{
  font-size: 16px;
}

.txt-14{
  font-size: 14px;
}

.t-bold{
  font-weight: bold;
}

.w-30
{
  width: 30%;
}

.w-40
{
  width: 40%;
}

.w-69
{
  width: 69%;
}

.w-70
{
  width: 70%;
}

.w-100
{
  width: 100% !important;
}

.ta-c
{
  text-align: center;
}

.pd-lr{
  padding-left: 16px;
  padding-right: 16px;
}

.pd-t{
  padding-top: 64px;
}

.pd8{
  padding: 8px;
}

.pd16{
  padding: 16px;
}

.h-64
{
  height: 64px;
}


.h-128
{
  height: 128px;
}

.h-192
{
  height: 192px;
}

.h-256
{
  height: 256px;
}

.m-t{
  margin-top: auto !important;
}

.m-b{
  margin-bottom: auto !important;
}

.ml-a {
  margin-left: auto;
}

.mr-a {
  margin-right: auto;
}

.m-lr{
  margin-left: auto;
  margin-right: auto;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #666 #222;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #eee;
}

*::-webkit-scrollbar-thumb {
  background-color: #666;
  border-radius: 2px;
  border: 1px solid #666;
}

nav{
  height: 32px;
  width: 256px;
  position: absolute;
  top: 8px
}

/* cyrillic-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0-ExdGM.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0aExdGM.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* latin-ext */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0yExdGM.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/ptsans/v17/jizaRExUiTo99u79D0KExQ.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

.debugger{
  position: fixed;
  z-index: 9999;
  background: #eeeeee;
  color: #111111;
  width: 128px;
  height: 64px;
  right: 32px;
  top:32px;
  border: red 1px solid;
  border-radius: 8px;
}

.inputs{
    height: 32px;
    background: #ddd;
    border: 1px solid #ccc;
    border-radius: 6px;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 2px;
    outline: none;
    color: #5648ed;
    position: relative;
    width:90%;
    text-align: center;
}

.img_my{
  width: 128px;
  height: 170px;
  margin: 8px;
  float: left;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(10,10,10,0.25);
  transition-property: border, box-shadow, transform;
  transition:  0.3s ease;
}

.img_my:hover{
  transform: scale(1.01);
  cursor: pointer;
  box-shadow: 0px 0px 10px rgba(51,100,225,0.5);
  border: 2px solid #3366ff;
}

.img_img{
  width: 128px;
  height: 128px;
  background-color: #fff;
  background-size: 100%;
  border-radius: 8px;
}

.img{
  width: 120px;
  height: 120px;
  margin-top: 4px;
  background-color: #fff;
}

.img_name{
  width: 128px;
  height: 48px;
  line-height: 48px;
  font-size: 10px;
}
</style>
