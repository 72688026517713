import { reactive, readonly } from 'vue';

const state = reactive({
    items: JSON.parse(localStorage.getItem('cart') || '[]')
});

const methods = {
    get() {
        return state.items;
    },
    add(item) {
        // Логика добавления элемента
        state.items.push(item);
        localStorage.setItem('cart', JSON.stringify(state.items));
        // Оповещение об обновлении корзины, если нужно
    },
    plus(item){
        let in_cart = state.items;//this.get();

        let itm = in_cart.find(i => i.id == item.id);
        if(itm == undefined || itm == null){
            in_cart.push(item);
        }else{
            itm.count += item.count;
        }

        localStorage.setItem("cart", JSON.stringify(in_cart));
    },
    setcount(item, count){
        let in_cart = state.items;//this.get();

        let itm = in_cart.find(i => i.id == item.id);
        if(itm == undefined || itm == null){
            in_cart.push(item);
        }else{
            itm.count = count;
        }

        localStorage.setItem("cart", JSON.stringify(in_cart));
    },
    minus(item){
        let in_cart = state.items;//this.get();
        if(in_cart.length > 0) {
            let itm = in_cart.find(i => i.id == item.id);
            itm.count--;
            if (itm.count <= 0) {
                in_cart.splice(in_cart.indexOf(itm), 1);
            }
        }

        localStorage.setItem("cart", JSON.stringify(in_cart));
    },
    remove(item){
        let in_cart = state.items;
        let itm = in_cart.find(i => i.id == item.id);
        in_cart.splice(in_cart.indexOf(itm), 1);
        localStorage.setItem("cart", JSON.stringify(in_cart));
    },
    clear(){
        state.items.length = 0;
        localStorage.setItem('cart', JSON.stringify(state.items));
    },
};

// Экспортируем состояние и методы
export default {
    state: readonly(state), // предотвращаем внешние изменения напрямую
    ...methods
};